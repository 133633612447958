<fd-shellbar [size]="'xl'">
    <fd-shellbar-logo>
        <a routerLink="/"
           class="fd-shellbar__logo fd-shellbar__logo--pex-portal"
           aria-label="SAP">
        </a>
    </fd-shellbar-logo>
    <fd-shellbar-title>
        {{(contextProvider?.context$ | async)?.appTitle}}
    </fd-shellbar-title>
    <fd-shellbar-subtitle>
        {{environmentName}}
    </fd-shellbar-subtitle>
    <fd-shellbar-actions *ngIf="isAuthenticated$ | async"
                         [closePopoverOnSelect]="true"
                         [user]="(shellBarUser$ | async)!"
                         [userMenu]="userMenu">
    </fd-shellbar-actions>
</fd-shellbar>
