import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CapitalizeFirstLetterPipe} from './capitalize-first-letter.pipe';
import {MapByIdPipe} from './map-by-id.pipe';
import {SortPipe} from './sort.pipe';
import {SafePipe} from './safe.pipe';
import {NobrHyphenPipe} from './nobr-hyphen.pipe';

@NgModule
({
     declarations: [
         CapitalizeFirstLetterPipe,
         MapByIdPipe,
         SafePipe,
         SortPipe,
         NobrHyphenPipe,
     ],
     exports: [
         CapitalizeFirstLetterPipe,
         MapByIdPipe,
         SafePipe,
         SortPipe,
         NobrHyphenPipe
     ],
     imports: [
         CommonModule
     ]
 })
export class PipeModule {
}
