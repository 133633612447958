import {Injectable} from '@angular/core';

import {EntityChangeSet} from './entity-change-set';
import {ResourceBase}    from '../../core/model/resource/resource-base.model';

@Injectable
({
     providedIn: 'root'
})
export class EntityChangeTracker<TEntity extends ResourceBase<TKey>, TKey> {

    private _changes: EntityChangeSet<TEntity, TKey> = new EntityChangeSet();

    get changes(): EntityChangeSet<TEntity, TKey> {
        return EntityChangeSet.fromJson<TEntity, TKey>(this._changes.toJson());
    }

    public addCreated(entity: TEntity) {
        this._changes.addCreated(entity);
    }

    public addModified(entity: TEntity) {
        this._changes.addModified(entity);
    }

    public addDeleted(entityId: TKey) {
        this._changes.addDeleted(entityId);
    }

    public hasChanges(): boolean {
        return this._changes.hasChanges();
    }

    public reset() {
        this._changes = new EntityChangeSet();
    }
}
