import {Guid} from 'guid-typescript';

export abstract class ModelBase {
    private _correlationId: Guid;

    public constructor() {
        this.correlationId = Guid.create();
    }

    get correlationId(): Guid {
        return this._correlationId;
    }

    set correlationId(value: Guid) {
        this._correlationId = value;
    }
}
