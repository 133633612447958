export class RegistrationParams {
    public emailAddress: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public password: string | null = null;
    public tenantUuid: string | null = null;

    public constructor(init: Partial<RegistrationParams> = { }) {
        Object.assign(this, init);
    }
}
