import {Component, OnInit} from '@angular/core';

import {AuthService}       from './core/auth/auth.service';

@Component
({
     selector: 'use-root',
     templateUrl: './app.component.html',
     styleUrls: ['./app.component.scss']
 })
export class AppComponent
    implements OnInit {
    public constructor(public authService: AuthService) {
    }

    public async ngOnInit() {
    }
}
