<fd-layout-panel [attr.aria-busy]="selectedTabIndex === 2">
    <fd-layout-panel-header>
        <fd-layout-panel-head>
            <h1 class="fd-has-font-weight-bold fd-has-type-5 fd-has-color-text-2">
                <use-button-with-backplate *ngIf="selectedTabIndex !== 0"
                                           (click)="onCancelClick()">
                </use-button-with-backplate>
                Login
            </h1>
        </fd-layout-panel-head>
    </fd-layout-panel-header>
    <fd-layout-panel-body>
        <fd-busy-indicator [loading]="selectedTabIndex === 2"></fd-busy-indicator>
        <ng-container *ngIf="selectedTabIndex == 0">
            <form [formGroup]="userNameForm">

                <fdp-input [placeholder]="userNamePlaceholder"
                           [state]="getFioriControlState(userNameControl)"
                           type="text"
                           name="userName"
                           formControlName="userName"
                           id="userName">
                </fdp-input>

                <fdp-button (click)="onAcceptUserNameClick($event)"
                            label="Continue"
                            buttonType="emphasized"
                            class="use-login-button"
                            [disabled]="!!userNameControl.errors || userNameControl.pending">
                </fdp-button>

            </form>
        </ng-container>
        <ng-container *ngIf="selectedTabIndex == 1">
            <use-domain-context [organizationName]="selectedTenant?.name"
                                [userIdentifier]="selectedUserName">
            </use-domain-context>
            <form id="tenantForm"
                  [formGroup]="tenantForm">
                <ng-container *ngIf="tenants.length > 1">
                    <div>Select an environment:</div>
                    <fd-select [(value)]="selectedEnvironmentId"
                               class="use-environment-selector"
                               placeholder="Select an environment...">
                        <fd-option *ngFor="let tenant of tenants"
                                   [value]="tenant.environment.id.toString()">
                            {{tenant.environment.name}}
                        </fd-option>
                    </fd-select>
                </ng-container>
                <fdp-button (click)="OnAcceptTenantClick($event)"
                            label="Continue"
                            buttonType="emphasized"
                            class="use-login-button">
                </fdp-button>
            </form>
        </ng-container>
    </fd-layout-panel-body>
    <fd-layout-panel-footer>
        EPI-USE (c) 2022
    </fd-layout-panel-footer>
</fd-layout-panel>
