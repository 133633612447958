<div class="breadcrumbs">
    <fd-breadcrumb class="fd-has-type-2">
        <ng-container *ngFor="let crumb of breadcrumbs">
            <ng-container *ngTemplateOutlet="isCurrentMenuItem(crumb.navTo)
                ? nonNavigable
                : navigable
                ; context: { $implicit: crumb }">
            </ng-container>
        </ng-container>
        <ng-template #navigable
                     let-crumb>
            <fd-breadcrumb-item>
            <span fd-breadcrumb-link
                  class="fd-has-type-1"
                  (click)="navTo(crumb.navTo)">
                <fd-icon *ngIf="!crumb.navTo.parent"
                         glyph="home">
                </fd-icon>
                {{ crumb.name }}
            </span>
            </fd-breadcrumb-item>
        </ng-template>
        <ng-template #nonNavigable
                     let-crumb>
            <fd-breadcrumb-item class="fd-has-type-1">
            <span>
                <fd-icon *ngIf="!crumb.navTo.parent"
                         glyph="home">
                </fd-icon>
                {{ crumb.name }}
            </span>
            </fd-breadcrumb-item>
        </ng-template>
    </fd-breadcrumb>
</div>
<fd-layout-grid class="use-app-grid">
    <use-portal-app *ngFor="let app of apps$ | async"
                    (appNavigateEvent)="onItemNav($event)"
                    [app]="app">
    </use-portal-app>
</fd-layout-grid>
