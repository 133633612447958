export class AuditEntryType {
    private _id: number;
    private _name: string;
    private _description: string;

    public static from(auditEntryType: AuditEntryType): AuditEntryType {
        const auditEntType: AuditEntryType = new AuditEntryType();

        auditEntType.id = auditEntryType.id;
        auditEntType.name = auditEntryType.name;
        auditEntType.description = auditEntryType.description;

        return auditEntType;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }
}
