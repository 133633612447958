import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {RoleEnum} from '../role.enum';
import {AppContextProvider} from '../app-context.provider';

@Injectable
({
    providedIn: 'root'
})
export class RoleService {

    constructor(private contextProvider: AppContextProvider) {
    }

    public hasAdminMenuAccess(): Observable<boolean> {
        return this.hasAnyRole([RoleEnum.ADMIN, RoleEnum.ROLE_ADMIN]);
    }

    public hasAnyRole(roles: RoleEnum[]): Observable<boolean> {
        return this.contextProvider.context$
            .pipe(map(ctx => ctx.userRoles
                    .some(role => roles.includes(role.id!))));
    }

    public isAdmin(): Observable<boolean> {
        return this.contextProvider.context$
            .pipe(map(ctx => ctx.userRoles
                    .map(role => role.id)
                    .includes(RoleEnum.ADMIN)));
    }

    public isRoleAdmin(): Observable<boolean> {
        return this.contextProvider.context$
            .pipe(map(ctx => ctx.userRoles
                    .map(role => role.id)
                    .includes(RoleEnum.ROLE_ADMIN)));
    }
}
