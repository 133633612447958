<use-status-board [status]="status"
                  [statuses]="{
                    'error': error,
                    'expired': expired,
                    'input': input,
                    'reset': reset,
                    'resetting': resetting,
                    'sending': sending,
                    'sent': sent
                  }">
</use-status-board>

<ng-template #error>
    <div class="panel panel-centered">
        <div class="error">
            <fd-icon size="xl"
                     glyph="message-error">
            </fd-icon>
        </div>
        <div>
            <p class="error panel__emphasized-text">Error</p>
            <p>Unable to reset password.</p>
            <p>Please try again later.</p>
        </div>
    </div>
</ng-template>

<ng-template #expired>
    <div class="panel panel-centered">
        <div class="warning">
            <fd-icon size="xl"
                     glyph="message-warning">
            </fd-icon>
        </div>
        <div>
            <p class="warning panel__emphasized-text">Expired</p>
            <p>Your password reset token has expired.</p>
        </div>
        <div>
            <a (click)="onResendClick()">
                Reset password
            </a>
        </div>
    </div>
</ng-template>

<ng-template #input>
    <div class="panel">
        <div class="neutral panel__emphasized-text">
            Reset password
        </div>
        <form #passwordResetForm="ngForm"
              useConfirmPassword>
            <div fd-form-set
                 class="form-set-width-1">
                <div fd-form-item>
                    <label fd-form-label
                           for="password">
                        New password
                    </label>
                    <input fd-form-control
                           required
                           id="password"
                           type="password"
                           #newPassword="ngModel"
                           [state]="getFioriControlState(newPassword)"
                           [ngModelOptions]="{ standalone: true }"
                           [(ngModel)]="resetParams.password">
                    <use-form-control-error controlCommonName="new password"
                                            [control]="newPassword">
                    </use-form-control-error>
                </div>
                <div fd-form-set
                     class="form-set-width-1">
                    <div fd-form-item>
                        <label fd-form-label
                               for="confirmPassword">
                            Confirm new password
                        </label>
                        <input fd-form-control
                               required
                               id="confirmPassword"
                               type="password"
                               #confirmNewPassword="ngModel"
                               [state]="getFioriControlState(confirmNewPassword)"
                               [ngModelOptions]="{ standalone: true }"
                               [(ngModel)]="confirmNewPass">
                        <use-form-control-error controlCommonName="confirm new password"
                                                [control]="confirmNewPassword">
                        </use-form-control-error>
                    </div>
                </div>
            </div>
            <button fd-button
                    options="emphasized"
                    [disabled]="passwordResetForm.invalid"
                    (click)="onResetClick()">
                Reset
            </button>
        </form>
    </div>
</ng-template>

<ng-template #reset>
    <div class="panel panel-centered">
        <div class="success">
            <fd-icon size="xl"
                     glyph="message-success">
            </fd-icon>
        </div>
        <div>
            <p class="success panel__emphasized-text">Reset</p>
            <p>Your password has been successfully reset.</p>
        </div>
        <div>
            <a [routerLink]="['../login']">Login</a>
        </div>
    </div>
</ng-template>

<ng-template #resetting>
    <div class="panel panel-centered">
        <div>
            <fd-busy-indicator [loading]="true"></fd-busy-indicator>
        </div>
        <div>
            <p class="pending panel__emphasized-text">Resetting</p>
            <p>Your password is being reset.</p>
        </div>
    </div>
</ng-template>

<ng-template #sending>
    <div class="panel panel-centered">
        <div>
            <fd-busy-indicator [loading]="true"></fd-busy-indicator>
        </div>
        <div>
            <p class="pending panel__emphasized-text">Sending email</p>
            <p>Your password reset email is being sent.</p>
        </div>
    </div>
</ng-template>

<ng-template #sent>
    <div class="panel panel-centered">
        <div class="neutral">
            <fd-icon size="xl"
                     glyph="email">
            </fd-icon>
        </div>
        <div>
            <p class="neutral panel__emphasized-text">Sent</p>
            <p>Your password reset email has been sent.</p>
        </div>
    </div>
</ng-template>
