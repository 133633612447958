export class Language {
    private _id: number;
    private _languageCode: string;
    private _description: string;

    public static from(language: Language): Language {
        const lang: Language = new Language();

        lang.id = language.id;
        lang.languageCode = language.languageCode;
        lang.description = language.description;

        return lang;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get languageCode(): string {
        return this._languageCode;
    }

    set languageCode(value: string) {
        this._languageCode = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }
}
