import {PortalMenuItem} from './portal-menu-item.model';
import {ResourceBase}   from './resource-base.model';

export class PortalMenu
    extends ResourceBase<number> {
    private _id: number;
    private _name: string;
    private _description: string;
    private _rootItemId: number;
    private _menuItems: Array<PortalMenuItem>;
    private _rootItem: PortalMenuItem;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get rootItemId(): number {
        return this._rootItemId;
    }

    set rootItemId(value: number) {
        this._rootItemId = value;
    }

    get menuItems(): Array<PortalMenuItem> {
        return this._menuItems;
    }

    set menuItems(value: Array<PortalMenuItem>) {
        this._menuItems = value;
    }

    get rootItem(): PortalMenuItem {
        return this._rootItem;
    }

    set rootItem(value: PortalMenuItem) {
        this._rootItem = value;
    }

    public static from(portalMenu: PortalMenu): PortalMenu {
        const menu = new PortalMenu();

        menu.id = portalMenu.id;
        menu.description = portalMenu.description;
        menu.name = portalMenu.name;
        menu.rootItemId = portalMenu.rootItemId;
        menu.correlationId = portalMenu.correlationId;

        // We create a root menu item as a navigation convenience.

        const rootItem = new PortalMenuItem();
        rootItem.id = portalMenu.rootItemId;
        rootItem.parent = undefined;
        rootItem.children = PortalMenuItem.fromItems(portalMenu.menuItems, rootItem);

        menu.rootItem = rootItem;

        return menu;
    }
}
