import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {NGXLogger} from 'ngx-logger';

import {PortalMenuItem} from '../../../core/model/resource/portal-menu-item.model';
import {PortalAppService} from './portal-app.service';
import {AlertConfig, AlertService, IconFont} from "@fundamental-ngx/core";

@Component
({
    selector: 'use-portal-app',
    templateUrl: './portal-app.component.html',
    styleUrls: ['./portal-app.component.scss'],
})
export class PortalAppComponent
    implements OnInit, AfterViewInit {

    @Input() app: PortalMenuItem;

    @Output() appNavigateEvent = new EventEmitter<any>();

    private readonly BSS_ICON_PREFIX = 'icon-';
    private readonly SAP_ICON_PREFIX :string = 'sap-icon://';
    private readonly SAP_ICON_FONT_TYPE :IconFont = 'SAP-icons';
    private readonly SAP_ICON_FONT_TYPE_TNT :IconFont = 'SAP-icons-TNT';
    private readonly SAP_ICON_FONT_TYPE_BSS :IconFont = 'BusinessSuiteInAppSymbols';

    constructor(private logger: NGXLogger,
                private portalAppService: PortalAppService,
                private alertService: AlertService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        if (this.app.error){
            this.logger.error(this.app.error);
            let appNameDisplay= this.app.parent? `${this.app.parent.name} -> ${this.app.name}`: this.app.name;
            this.displayAlertAsError(`Invalid configuration for application: ${appNameDisplay}`);
        }

    }

    public onClick() {
        this.portalAppService.checkExecuteAction(this.app, this.appNavigateEvent);
    }

    public get useSapIcon(): boolean {
        return !!(this.app && this.app.iconName && this.app.iconName.startsWith(this.SAP_ICON_PREFIX));
    }

    public get getSapIconName(): string {
        var iconName = '';
        if (this.app && this.app.iconName){
            iconName = this.app.iconName.split('/').pop() || '';
            iconName = iconName.replace(this.BSS_ICON_PREFIX,'');
        }

        return iconName;
    }

    public get iconFontType(): IconFont {

        if (!this.useSapIcon) {
            return this.SAP_ICON_FONT_TYPE;
        }

        var iconNameBreakdown = this.app.iconName.replace(this.SAP_ICON_PREFIX, '').split('/');

        if (iconNameBreakdown.length == 1){
            return this.SAP_ICON_FONT_TYPE;
        }

        if (iconNameBreakdown[0] == 'BusinessSuiteInAppSymbols'){
            return this.SAP_ICON_FONT_TYPE_BSS;
        }

        return this.SAP_ICON_FONT_TYPE_TNT;
    }

    public get appImageUrl(): string {

        const imageName = this.app && this.app.iconName
            ? this.app.iconName
            : 'default.png';

        return `/assets/${imageName}`;
    }

    private displayAlertAsError(message: string): void {
        this.alertService.open(message, <AlertConfig>{
            type: 'error',
            dismissible: true,
            duration:5000
        });
    }


}
