import {pipe, Observable, OperatorFunction, Subscribable, UnaryFunction} from 'rxjs';
import {filter} from "rxjs/operators";

function safeObserve(subscribable: Subscribable<any>): void {

    const subscription = subscribable.subscribe({
        complete: () => {
            subscription.unsubscribe();
        }
    });
}

const coalesce = (...args) => args.find((_) => ![null, undefined].includes(_));
const coalesceNum = (...args) => {
    const c = coalesce(...args);
    if (!!c) {
        return +c;
    }
        return undefined;
};

const tenantId = () => localStorage['tenant-id']!;

const filterNullish = <T>(): UnaryFunction<Observable<T | null | undefined>, Observable<T>> => {
    return pipe(
        filter(x => x != null) as OperatorFunction<T | null |  undefined, T>
    );
};

export
{
    coalesce,
    coalesceNum,
    filterNullish,
    safeObserve,
    tenantId
};
