import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';

import {LoggerModule} from 'ngx-logger';
import {TranslateModule} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import * as Extensions from './shared/extensions';

import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {HomeModule} from './feature/home/home.module';

import {environment} from '../environments/environment';
import {DebugTools} from './core/debug-tools';
import {AppComponent} from './app.component';
import {AuthInterceptor} from './core/auth/interceptor/auth.interceptor';
import {AuthGuardWithForcedLogin} from './core/auth/auth-guard-with-forced-login.service';
import {PageNotFoundComponent} from './core/page-not-found/page-not-found.component';

@NgModule
({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        LoggerModule.forRoot
        ({
            level: environment.logLevel,
            serverLogLevel: environment.serverLogLevel
        }),
        CoreModule,
        SharedModule,
        HomeModule,
        TranslateModule.forRoot(),
        RouterModule.forRoot
        (
            [
                {path: '', redirectTo: '/home', pathMatch: 'full'},
                {
                    path: 'admin',
                    canLoad: [AuthGuardWithForcedLogin],
                    loadChildren: () => import('./feature/admin/admin.module').then(m => m.AdminModule),
                },
                {
                    path: 'profile',
                    canActivate: [AuthGuardWithForcedLogin],
                    loadChildren: () => import('./feature/user/user.module').then(m => m.UserModule)
                },
                {path: '**', component: PageNotFoundComponent},
            ],
            {relativeLinkResolution: 'corrected'}
        ),
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        CookieService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    public constructor(private debugTools: DebugTools) {
        debugTools.initialize();
    }
}
