import {Injectable} from '@angular/core';

import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {PortalMenuItem} from '../../../core/model/resource/portal-menu-item.model';
import {AppContextProvider} from '../../../core/app-context.provider';

@Injectable
({
    providedIn: 'root'
})
export class AppBrowserService {

    private _currentMenuParent: NullableUndefinable<PortalMenuItem>;

    public get currentMenuParent(): NullableUndefinable<PortalMenuItem> {
        return this._currentMenuParent;
    }

    public set currentMenuParent(value: NullableUndefinable<PortalMenuItem>) {
        this._currentMenuParent = value;
    }

    public get isTopMenuLevel(): boolean {
        return !this.currentMenuParent || (this.currentMenuParent && !this.currentMenuParent.parent);
    }

    public get breadcrumbs(): { name: string; navTo: PortalMenuItem }[] {

        const crumbs: PortalMenuItem[] = [];
        let crumb = this.currentMenuParent;

        while (crumb) {
            crumbs.push(crumb);
            crumb = crumb.parent;
        }

        crumbs.reverse();

        return crumbs.map(cr => ({
            name: cr.parent ? cr.name : 'Home',
            navTo: cr
        }));
    }

    public constructor(private contextProvider: AppContextProvider) {
    }

    public getLatestPortalMenu(): Observable<PortalMenuItem> {

        return this.contextProvider
            .context$
            .pipe(map((ctx) => this.currentMenuParent = ctx.portalMenu.rootItem));
    }

    public navOneMenuLevelUp() {

        if (this.currentMenuParent && this.currentMenuParent.parent) {
            this.currentMenuParent = this.currentMenuParent.parent;
        }
    }

    public navTo(menuItem: PortalMenuItem): void {
        this.currentMenuParent = menuItem;
    }

    public isCurrentMenuItem(menuItem: PortalMenuItem): boolean {
        return menuItem === this.currentMenuParent;
    }
}
