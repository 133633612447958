declare global
{
    // noinspection JSUnusedGlobalSymbols
    interface StringConstructor
    {
        isNullOrEmpty(str: Nullable<string>): boolean;
    }
}

String.isNullOrEmpty = (str: string) => !str;

export {};
