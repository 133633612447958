import {HttpHeaders} from "@angular/common/http";

import {Constants} from "../core/constants";
import {environment} from "../../environments/environment";

export class HttpHeadersBuilder {

    constructor(private instance?: HttpHeaders) {
        this.instance = instance ?? new HttpHeaders();
    }

    public addContentTypeJsonHeader(): HttpHeadersBuilder {
        this.instance = this.instance!.append(Constants.MediaType.CONTENT_TYPE, Constants.MediaType.APPLICATION_JSON);
        return this;
    }

    public addTenantIdHeader(tenantUuid?: string, throwIfNull = false): HttpHeadersBuilder {

        if (!tenantUuid) {
            if (throwIfNull) {
                throw new Error('Failed during authentication');
            }
        }
        else {
            this.instance = this.instance!.append(environment.tenantIdHeaderKey, tenantUuid);
        }

        return this;
    }

    public build(): HttpHeaders {
        return this.instance!;
    }
}
