import { NgModule }                              from '@angular/core';
import { CommonModule }                          from '@angular/common';

import {ButtonModule, FormModule, DialogModule, LayoutPanelModule} from '@fundamental-ngx/core';

import { FormControlErrorComponent }             from './form-control-error/form-control-error.component';
import { ModalFormComponent }                    from './modal-form/modal-form.component';
import { PipeModule }                            from '../../pipe/pipe.module';

@NgModule
({
    declarations: [
        FormControlErrorComponent,
        ModalFormComponent
    ],
    imports: [
        ButtonModule,
        CommonModule,
        FormModule,
        DialogModule,
        PipeModule,
        LayoutPanelModule
    ],
    exports: [
        FormControlErrorComponent,
        ModalFormComponent,
    ]
})
export class FormsModule {
}
