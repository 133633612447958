import {Component, Input} from '@angular/core';

@Component
({
    selector: 'use-domain-context',
    templateUrl: './domain-context.component.html',
    styleUrls: ['./domain-context.component.scss']
})
export class DomainContextComponent {
    @Input() public organizationName: string | undefined;
    @Input() public userIdentifier: string;
}
