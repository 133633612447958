import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FundamentalNgxCoreModule} from '@fundamental-ngx/core';

import {ShellBarComponent} from './shell-bar/shell-bar.component';
import {PortalAppsModule} from '../portal-app/portal-apps.module';
import {AuthGuardWithForcedLogin} from '../../core/auth/auth-guard-with-forced-login.service';
import {AppBrowserComponent} from '../portal-app/app-browser/app-browser.component';

@NgModule
({
     declarations: [
         ShellBarComponent,
     ],
     imports: [
         CommonModule,
         PortalAppsModule,
         FundamentalNgxCoreModule,
         RouterModule.forChild([
             {
                 path: 'home',
                 canActivate: [AuthGuardWithForcedLogin],
                 component: AppBrowserComponent,
             },
         ])
     ],
     exports: [
         ShellBarComponent,
     ]
 })
export class HomeModule {
}
