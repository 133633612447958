import { Directive, Input, OnInit }                                    from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive
({
    selector: '[useConfirmPassword]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ConfirmPasswordValidator,
        multi: true
    }]
})
export class ConfirmPasswordValidator
    implements OnInit, Validator {
    private static readonly DEFAULT_PASSWORD_CONTROL_NAME = 'password';
    private static readonly DEFAULT_PASSWORD_CONFIRM_CONTROL_NAME = 'confirmPassword';

    private _passwordControlName: string;
    private _passwordConfirmControlName: string;

    public ngOnInit(): void {
        this._passwordControlName = ConfirmPasswordValidator.DEFAULT_PASSWORD_CONTROL_NAME;
        this._passwordConfirmControlName = ConfirmPasswordValidator.DEFAULT_PASSWORD_CONFIRM_CONTROL_NAME;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const password = control.get(this._passwordControlName);
        const confirmPassword = control.get(this._passwordConfirmControlName);

        if (password && confirmPassword && password.value !== confirmPassword.value) {
            const validationErrors = { password: true };
            confirmPassword.setErrors(validationErrors);
            return validationErrors;
        } else {
            return null;
        }
    }

    @Input()
    public set passwordConfirmControlPairNames(controlPairName: string[]) {
        if (controlPairName) {
            if (controlPairName.length > 0) {
                this._passwordControlName = controlPairName[0];
            }
            if (controlPairName.length > 1) {
                this._passwordConfirmControlName = controlPairName[1];
            }
        }
    }
}
