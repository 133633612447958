import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ButtonModule, PanelModule} from '@fundamental-ngx/core';

import {ButtonWithBackplateComponent} from './component/buttons/button-with-backplate/button-with-backplate.component';
import {DialogsModule} from './component/dialogs/dialogs.module';
import {FormsModule} from './component/forms/forms.module';
import {StatusBoardComponent} from './component/status/status-board/status-board.component';
import {EntityChangeTracker} from './data/entity-change-tracker';
import {PipeModule} from './pipe/pipe.module';
import {ValidatorsModule} from './validators/validators.module';
import {TranslateModule} from '@ngx-translate/core';
import {SubscriptionManager} from "./subscription-manager";

@NgModule
({
     exports: [
         TranslateModule,
         DialogsModule,
         FormsModule,
         PipeModule,
         ValidatorsModule,
         ButtonWithBackplateComponent,
         StatusBoardComponent
     ],
     imports: [
         CommonModule,
         DialogsModule,
         FormsModule,
         PanelModule,
         PipeModule,
         ValidatorsModule,
         ButtonModule
     ],
     providers: [
         {
             provide: EntityChangeTracker,
             useFactory: () => new EntityChangeTracker()
         },
         {
             provide: SubscriptionManager,
             useFactory: () => new SubscriptionManager()
         }
     ],
     declarations: [
         ButtonWithBackplateComponent,
         StatusBoardComponent
     ]
 })
export class SharedModule {
}
