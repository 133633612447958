import {NgModule}                   from '@angular/core';
import {CommonModule}               from '@angular/common';

import {FundamentalNgxCoreModule}   from '@fundamental-ngx/core';

import {SharedModule}               from '../../shared/shared.module';
import {AppBrowserComponent}        from './app-browser/app-browser.component';
import {AppBrowserService}          from './app-browser/app-browser.service';
import {PortalAppComponent}         from './portal-app/portal-app.component';
import {PortalAppService}           from './portal-app/portal-app.service';

@NgModule
({
     declarations: [
         AppBrowserComponent,
         PortalAppComponent,
     ],
     imports: [
         CommonModule,
         FundamentalNgxCoreModule,
         SharedModule,
     ],
     providers: [
         AppBrowserService,
         PortalAppService,
     ],
 })
export class PortalAppsModule {
}
