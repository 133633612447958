<use-status-board [status]="status"
                  [statuses]="{
                    'error': error,
                    'expired': expired,
                    'resend': resend,
                    'resending' : resending,
                    'resent': resent,
                    'verified': verified,
                    'verifying': verifying
                  }">
</use-status-board>

<ng-template #error>
    <div class="panel panel-centered">
        <div class="error">
            <fd-icon size="xl"
                     glyph="message-error">
            </fd-icon>
        </div>
        <div>
            <p class="error panel__emphasized-text">Error</p>
            <p>Unable to verify email address.</p>
            <p>Please try again later.</p>
        </div>
    </div>
</ng-template>

<ng-template #expired>
    <div class="panel panel-centered">
        <div class="warning">
            <fd-icon size="xl"
                     glyph="message-warning">
            </fd-icon>
        </div>
        <div>
            <p class="warning panel__emphasized-text">Expired</p>
            <p>Your verification token has expired.</p>
        </div>
        <div>
            <a (click)="onRequestResendClick()">
                Resend verification email
            </a>
        </div>
    </div>
</ng-template>

<ng-template #resend>
    <div class="panel">
        <div class="neutral panel__emphasized-text">
            Resend verification email
        </div>
        <form>
            <div fd-form-set
                 class="form-set-width-1">
                <div fd-form-item>
                    <label fd-form-label
                           for="email">
                        Email address
                    </label>
                    <input fd-form-control
                           required
                           id="email"
                           name="email"
                           type="email"
                           maxlength="255"
                           useValidEmailAddress
                           #email="ngModel"
                           [state]="getFioriControlState(email)"
                           [(ngModel)]="resendParams.emailAddress">
                    <use-form-control-error controlCommonName="email address"
                                            [control]="email">
                    </use-form-control-error>
                </div>
            </div>
            <button fd-button
                    options="emphasized"
                    [disabled]="email.invalid"
                    (click)="onResendClick()">
                Resend
            </button>
        </form>
    </div>
</ng-template>

<ng-template #resending>
    <div class="panel panel-centered">
        <div>
            <fd-busy-indicator [loading]="true"></fd-busy-indicator>
        </div>
        <div>
            <p class="pending panel__emphasized-text">Resending</p>
            <p>Your verification email is being resent.</p>
        </div>
    </div>
</ng-template>

<ng-template #resent>
    <div class="panel panel-centered">
        <div class="neutral">
            <fd-icon size="xl"
                     glyph="email">
            </fd-icon>
        </div>
        <div>
            <p class="neutral panel__emphasized-text">Sent</p>
            <p>Verification email has been resent.</p>
        </div>
    </div>
</ng-template>

<ng-template #verified>
    <div class="panel panel-centered">
        <div class="success">
            <fd-icon size="xl"
                     glyph="message-success">
            </fd-icon>
        </div>
        <div>
            <p class="success panel__emphasized-text">Verified</p>
            <p>Your email address has been successfully verified.</p>
        </div>
        <div>
            <a [routerLink]="['../login']">Login</a>
        </div>
    </div>
</ng-template>

<ng-template #verifying>
    <div class="panel panel-centered">
        <div>
            <fd-busy-indicator [loading]="true"></fd-busy-indicator>
        </div>
        <div>
            <p class="pending panel__emphasized-text">Verifying</p>
            <p>Your email address is being verified.</p>
        </div>
    </div>
</ng-template>
