import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {EMPTY, Observable, of, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

import {NGXLogger} from 'ngx-logger';
import {ShellbarUser, ShellbarUserMenu} from '@fundamental-ngx/core';

import '../../../core/string-helper.module';
import {AuthService} from '../../../core/auth/auth.service';
import {AppContextProvider} from '../../../core/app-context.provider';
import {RoleService} from '../../../core/auth/role.service';
import {EnvironmentEnum} from '../../../core/environment.enum';
import {UserProfile} from '../../../core/model/resource/user-profile.model';
import {AppContext} from '../../../core/model/app-context.model';


interface IUserMenuItem extends ShellbarUserMenu {
    enabled: boolean;
}

interface IEnvironment {
    production: boolean;
}

@Component
({
    selector: 'use-shell-bar',
    templateUrl: './shell-bar.component.html',
    styleUrls: ['./shell-bar.component.scss'],
})
export class ShellBarComponent
    implements OnDestroy,
        OnInit {

    private _subscriptions: Subscription = new Subscription();
    private _userMenuMap: Map<string, IUserMenuItem>;
    public userMenu: IUserMenuItem[] = [];

    public shellBarUser$: Observable<ShellbarUser> = EMPTY;

    public environmentName = '';

    get isAuthenticated$() {
        return this.auth.isAuthenticated$;
    }

    public constructor(
        public contextProvider: AppContextProvider,
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private roleService: RoleService,
        private logger: NGXLogger) {
    }

    public async onProfileClick() {
        await this.router.navigate(['profile']);
    }

    public async onLogoutClick() {
        this.auth.logout();
    }

    public async onPortalAdminClick() {

        await this.router.navigate(
            ['admin'],
            {
                relativeTo: this.route.parent
            });
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public ngOnInit(): void {

        const hasContext = (c: AppContext) => {

            const result = typeof (c?.user?.id) === 'number';
            this.logger.debug(`Has context: ${result}`);
            return result;
        };

        const onContext = (c: AppContext) => {

            this.logger.debug('Processing onContext...');
            this.initializeMenu(c);
            this.initializeShellBar(c);
        };

        const onNoContext = () => {
            this.logger.debug('Processing onNoContext...');
            this.resetShellBarState();
        };

        const processContext = (c: AppContext) => {

            if (hasContext(c)) {
                onContext(c);
            } else {
                onNoContext();
            }
        };

        const sub = this.contextProvider.context$
            .pipe(tap(c => processContext(c)))
            .subscribe();

        this._subscriptions.add(sub);
    }

    private initializeShellBar(c: AppContext) {

        this.shellBarUser$ = of(ShellBarComponent.toShellBarUser(c.user));

        switch (c.environment?.id) {
            case EnvironmentEnum.PRODUCTION: this.environmentName = ''; break;
            case EnvironmentEnum.QA: this.environmentName = 'Pre-Production'; break;
            default: this.environmentName = c.environment.name; break;
        }
    }

    private initializeMenu(c: AppContext) {

        this.initializeMenuDefaults();

        this._userMenuMap.get('profileItem')!.enabled = !!c?.user;

        const sub = this.roleService.hasAdminMenuAccess()
            .pipe(tap(isAdmin => {
                this.logger.debug(`IsAdmin: ${isAdmin}`);
                this._userMenuMap.get('adminItem')!.enabled = isAdmin === true;
            }))
            .pipe(tap(() => (this.userMenu = this.userMenu.filter(i => i.enabled)))
        ).subscribe({
            next: () => {},
            error: () => {},
            complete: () => sub.unsubscribe()
        });
    }

    private static toShellBarUser(user: Nullable<UserProfile>): ShellbarUser {

        if (!user) {
            return <ShellbarUser>{};
        }

        return <ShellbarUser>{
            colorAccent: Number(user.colorAccent),
            fullName: `${user.firstName} ${user.lastName}`,
            image: user.image,
            initials: user.initials
        };
    }

    private initializeMenuDefaults() {

        const signOutItem = <IUserMenuItem>{
            text: 'Sign Out',
            callback: this.onLogoutClick.bind(this),
            enabled: true
        };
        const adminItem = <IUserMenuItem>{
            text: 'Portal administration',
            callback: this.onPortalAdminClick.bind(this),
            enabled: false
        };
        const profileItem = <IUserMenuItem>{
            text: 'Profile',
            callback: this.onProfileClick.bind(this),
            enabled: false
        };

        const map = this._userMenuMap = new Map<string, IUserMenuItem>();
        map.set('signOutItem', signOutItem);
        map.set('adminItem', adminItem);
        map.set('profileItem', profileItem);

        this.userMenu = [
            profileItem,
            adminItem,
            signOutItem
        ];
    }

    private resetShellBarState() {
        this.shellBarUser$ = EMPTY;
        this.userMenu = [];
    }
}
