// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {NgxLoggerLevel} from "ngx-logger";
import {AuthConfig, OAuthModuleConfig} from "angular-oauth2-oidc";

export const environment = {

    production: false,
    appTitle: 'PayrollExpress Portal',
    logLevel: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.OFF,
    tenantIdHeaderKey: 'X-USE-EWP-TENANT-ID',
    server: {
        baseUrl: 'https://portalqas.pex.ecpcloud.net'
    },
    keycloak: {
        baseUrl: `https://portalqas.pex.ecpcloud.net/keycloak/auth`
    },
    authModuleConfig: <OAuthModuleConfig & {initialized: Boolean}> {
        initialized: false,
        resourceServer: {
            allowedUrls: ['/ewp/api'],
            sendAccessToken: true,
        }
    },
    tenant: {
        authConfig: <AuthConfig>{
            issuer: '${keycloak.baseUrl}/realms/${realm}',
            clientId: 'pexportal-openid', // The "Auth Code + PKCE" client
            responseType: 'code',
            redirectUri: window.location.origin + '/home',
            // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
            scope: 'openid profile email', // Ask offline_access to support refresh token refreshes
            useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
            // silentRefreshTimeout: 600000, // For faster testing
            timeoutFactor: 1, // For faster testing
            sessionChecksEnabled: false,
            sessionCheckInterval: null,
            showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
            clearHashAfterLogin: true, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040
            nonceStateSeparator: 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
        }
    },
    featureFlags: {
        userRegistration: {
            enabled: false
        }
    },
    services: {
        baseUrl: '',
        apiPath: '/ewp/api',
        authPath: '/ewp/auth',
    },
    form: {
        validation: {
            updateOn: 'change'
        }
    },
    ui: {
        isCompact: true
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
