import { Directive }                                                              from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';

import { Observable, of, timer }                                                  from 'rxjs';
import { switchMap }                                                              from 'rxjs/operators';

const validEmailAddress = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

@Directive
({
    selector: '[useValidEmailAddress]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: EmailAddressAsyncValidator,
        multi: true
    }]
})
export class EmailAddressAsyncValidator
    implements AsyncValidator {
    public validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        const emailAddress = control.value;
        const isValidEmailAddress = validEmailAddress.test(emailAddress);
        const validationResult = of(!isValidEmailAddress ? { pattern: true } : null);
        return timer(600).pipe(switchMap(() => validationResult));
    }
}
