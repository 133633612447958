import {NgModule, Optional, SkipSelf} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {FundamentalNgxCoreModule} from '@fundamental-ngx/core';
import {FundamentalNgxPlatformModule} from '@fundamental-ngx/platform';

import {environment} from '../../environments/environment';
import './string-helper.module';
import {AuthModule} from './auth/auth.module';
import {BusyOverlayComponent} from './busy-overlay/busy-overlay.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {SsoService} from './sso.service';
import {AppContextProvider} from './app-context.provider';
import {BusyIndicator} from './busy-indicator';
import {DebugTools} from './debug-tools';
import {ErrorHandler} from './error-handler.service';
import {SsoClient} from './client/sso.client';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {LoginComponent} from './auth/login/login.component';
import {PasswordResetComponent} from './auth/password-reset/password-reset.component';
import {VerificationComponent} from './auth/verification/verification.component';
import {RegistrationComponent} from './auth/registration/registration.component';

const routes: Routes = [
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'reset-password',
        component: PasswordResetComponent
    },
    {
        path: 'verify',
        component: VerificationComponent
    }
];

try {
    if (environment.featureFlags.userRegistration.enabled === true) {
        routes.unshift({
                           path: 'register',
                           component: RegistrationComponent
                       });
    }
} catch (e) {
}

@NgModule
({
     declarations: [
         PageNotFoundComponent,
         BusyOverlayComponent,
     ],
     imports: [
         CommonModule,
         AuthModule.forRoot(),
         BrowserAnimationsModule,
         FundamentalNgxCoreModule,
         FundamentalNgxPlatformModule,
         RouterModule.forChild(routes)
     ],
     exports: [
         AuthModule,
         PageNotFoundComponent,
     ],
     providers: [
         AppContextProvider,
         BusyIndicator,
         DebugTools,
         ErrorHandler,
         SsoClient,
         SsoService,
     ]
 })
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
