import {Injectable} from '@angular/core';

import {DialogRef, DialogService as FundamentalNgxDialogService} from '@fundamental-ngx/core';

import {DialogDeleteComponent} from './dialog-delete/dialog-delete.component';

@Injectable
({
    providedIn: 'root'
})
export class DialogService {

    public constructor(
        private dialogService: FundamentalNgxDialogService
    ) {
    }

    public confirmDeleteForResourceType(resourceName: string): DialogRef {

        return this.dialogService.open(DialogDeleteComponent, {
            data: {
                title: `remove ${resourceName}`,
                resourceName,
            }
        });
    }
}
