import {Injectable} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';
import {FormStates} from '@fundamental-ngx/core';

@Injectable
({
     providedIn: 'root'
 })
export class FioriFormControlStateService {
    public getFioriState(control: AbstractControl | AbstractControlDirective): Nullable<FormStates> {
        const isTouched = control.dirty || control.touched;
        if (control.invalid && isTouched) {
            return 'error';
        }
        if (control.value && isTouched) {
            return 'success';
        }

        return null;
    }
}
