import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {NGXLogger} from 'ngx-logger';

import {AuthService} from '../auth.service';
import {FioriFormControlStateService} from '../../forms/fiori-form-control-state.service';
import {VerificationParams} from '../../model/verification-params.model';
import {VerificationResendParams} from '../../model/verification-resend-params.model';
import {ErrorHandler} from '../../error-handler.service';
import {AlertConfig, AlertService, FormStates} from '@fundamental-ngx/core';

@Component
({
    selector: 'use-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.scss']
})
export class VerificationComponent
    implements OnDestroy, OnInit {
    private static readonly ALERT_ERR_MSG = 'An unknown error has occurred';

    public status: 'error' | 'expired' | 'resend' | 'resending' | 'resent' | 'verified' | 'verifying';
    public resendParams: VerificationResendParams;

    private subscriptions: Subscription;

    public constructor(
        private authService: AuthService,
        private fioriFormControlStateService: FioriFormControlStateService,
        private logger: NGXLogger,
        private route: ActivatedRoute,
        private errorHandler: ErrorHandler,
        private alertService: AlertService,
    ) {
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnInit(): void {
        this.initComponent();
    }

    private initComponent(): void {
        this.resendParams = new VerificationResendParams();
        this.subscriptions = new Subscription();

        this.initVerifyOrResendState();
    }

    private initVerifyOrResendState(): void {
        this.status = 'verifying';

        const subscription =
            this.route.queryParamMap.pipe(map(params => params.get('type')))
                .subscribe({
                    next: (type) => {
                        if (!type) {
                            this.initVerificationCall();
                        } else if (type === 'resend') {
                            this.status = 'resend';
                        } else {
                            this.status = 'error';
                        }
                    },
                    error: () => this.status = 'error'
                });

        this.subscriptions.add(subscription);
    }

    private initVerificationCall(): void {
        const subscription =
            this.route.queryParamMap
                .pipe(
                    map(params => {
                        const token = params.get('token');
                        const tenant = params.get('tenant');

                        if (!token) {
                            throw 'Verification Error: No verification token provided';
                        } else if (!tenant) {
                            throw 'Verification Error: No tenant ID provided';
                        }

                        return new VerificationParams({token, tenantUuid: tenant});
                    }),
                    switchMap(params => this.authService.verify(params)),
                )
                .subscribe({
                    next: () => this.status = 'verified',
                    error: (error) => this.handleError(error)
                });

        this.subscriptions.add(subscription);
    }

    public getFioriControlState(control: NgModel): Nullable<FormStates> {
        return this.fioriFormControlStateService.getFioriState(control);
    }

    public onRequestResendClick(): void {
        this.status = 'resend';
    }

    public onResendClick(): void {
        this.status = 'resending';

        const subscription =
            this.route.queryParamMap.pipe(
                map(params => {
                    const tenant = params.get('tenant');

                    if (!tenant) {
                        throw 'No tenant ID has been provided';
                    }

                    this.resendParams.tenantUuid = tenant;
                    return this.resendParams;
                }),
                switchMap(params => this.authService.resendVerificationEmail(params)),
            )
                .subscribe({
                    next: () => this.status = 'resent',
                    error: (error) => this.handleError(error)
                });

        this.subscriptions.add(subscription);
    }

    private handleError(error): void {
        const isHttpError = typeof error === 'object' && error.status;
        const isExpiredToken = error.status === 410;

        this.status = isHttpError && isExpiredToken ? 'expired' : 'error';

        if (!isHttpError) {
            this.logger.error(error);
        }
    }

    private displayAlert(
        type: string,
        message: string): void {
        this.alertService.open(message, <AlertConfig>{
            type,
            dismissible: false,
            duration: 10000
        });
    }
}
