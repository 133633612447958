import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EmailAddressAsyncValidator} from './email-address.async-validator';
import {ConfirmPasswordValidator} from './confirm-password.validator';
import {UserPropertyExistsAsyncValidator} from './user-property-exists.async-validator';

@NgModule({
    declarations: [
        ConfirmPasswordValidator,
        EmailAddressAsyncValidator,
        UserPropertyExistsAsyncValidator,
    ],
    exports: [
        ConfirmPasswordValidator,
        EmailAddressAsyncValidator,
        UserPropertyExistsAsyncValidator
    ],
    imports: [
        CommonModule
    ]
})
export class ValidatorsModule { }
