<div class="use-app-tile-container">
    <fd-tile type="launch"
             (click)="onClick()"
             class="use-app-tile">
        <div fd-tile-header>
            <div fd-tile-title>{{app.name | nobrhyphen}}</div>
            <div fd-tile-subtitle>{{app.description | nobrhyphen}}</div>
        </div>
        <div fd-tile-content>
            <img *ngIf="!useSapIcon"
                 fd-tile-logo
                 [src]="appImageUrl"
                 aria-label=""
                 alt="">
            <fd-icon *ngIf="useSapIcon"
                 [glyph]="getSapIconName"
                [font]="iconFontType">
            </fd-icon>
        </div>

    </fd-tile>
</div>
