<fd-dialog [dialogRef]="dialogRef">
    <fd-dialog-header>
        <h2 fd-title>
            {{ dialogRef.data?.title | capitalizeFirstLetter }}
        </h2>
        <button fd-dialog-close-button (click)="onCancel()" title="Close"></button>
    </fd-dialog-header>
    <fd-dialog-body>
        <ng-content></ng-content>
    </fd-dialog-body>
    <fd-dialog-footer>
        <ng-content select=".dialog-actions"></ng-content>
    </fd-dialog-footer>
</fd-dialog>
