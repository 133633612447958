import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';

import {Observable, of} from 'rxjs';
import {concatMap, filter, switchMap, tap} from 'rxjs/operators';

import {AuthService} from './auth.service';
import {safeObserve} from "../../shared/extensions";

@Injectable()
export class AuthGuardWithForcedLogin
    implements CanActivate, CanLoad {

    constructor(
        private authService: AuthService,
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {

        return this.authService.isDoneLoading$.pipe(
            filter(isDone => isDone),
            switchMap(_ => this.authService.isAuthenticated$),
            concatMap(isAuthenticated => isAuthenticated
                ? of(true)
                : of(false).pipe(tap(_ => safeObserve(this.authService.login())))));
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.authService.isDoneLoading$.pipe(
            filter(isDone => isDone),
            switchMap(_ => this.authService.isAuthenticated$),
            concatMap(isAuthenticated => isAuthenticated
                    ? of(true)
                    : of(false).pipe(tap(_ => safeObserve(this.authService.login())))));
    }
}
