import {Component, TemplateRef, ViewChild} from '@angular/core';

import { DialogRef }              from '@fundamental-ngx/core';

import { DialogCloseReasonEnum } from '../dialog-close-reason.enum';

@Component
({
    selector: 'use-dialog-delete',
    templateUrl: './dialog-delete.component.html',
    styleUrls: ['./dialog-delete.component.scss']
})
export class DialogDeleteComponent {
    public constructor(
        public dialogRef: DialogRef
    ) { }

    public onConfirmDelete(): void {
        this.dialogRef.close(DialogCloseReasonEnum.CONFIRM);
    }
}
