import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';
import {ActivatedRoute, ParamMap} from '@angular/router';

import {NGXLogger} from 'ngx-logger';

import {Subscription} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {AuthService} from '../auth.service';
import {FioriFormControlStateService} from '../../forms/fiori-form-control-state.service';
import {PasswordResetParams} from '../../model/password-reset-params.model';
import {RequestPasswordResetUsingEmailAddress} from '../../model/request-password-reset-using-email-address.model';
import {RequestPasswordResetUsingToken} from '../../model/request-password-reset-using-token.model';
import {FormStates} from '@fundamental-ngx/core';

@Component
({
    selector: 'use-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent
    implements OnDestroy, OnInit {
    public status: 'error' | 'expired' | 'input' | 'resetting' | 'reset' | 'sending' | 'sent';

    private subscriptions: Subscription;

    public confirmNewPass: string;
    public resetParams: PasswordResetParams;

    public constructor(
        private authService: AuthService,
        private fioriFormControlStateService: FioriFormControlStateService,
        private logger: NGXLogger,
        private route: ActivatedRoute,
    ) {
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnInit(): void {
        this.initComponent();
    }

    private initComponent(): void {
        this.resetParams = new PasswordResetParams();
        this.subscriptions = new Subscription();

        this.initComponentState();
    }

    private initComponentState(): void {
        this.status = 'resetting';

        const subscription =
            this.route.queryParamMap
                .pipe(tap(params => this.sendOrUserInputState(params)))
                .subscribe({
                        next: () => {
                        },
                        error: (error) => this.handleError(error)
                    }
                );

        this.subscriptions.add(subscription);
    }

    private sendOrUserInputState(params: ParamMap): void {
        if (params.get('email')) {
            this.sendResetRequestUsingProvidedEmailAddress(params);
        } else {
            this.waitForUserInput(params);
        }
    }

    private sendResetRequestUsingProvidedEmailAddress(params: ParamMap): void {
        if (!params.get('tenant')) {
            throw 'Password Reset Error: No tenant ID provided';
        }

        const emailAddress = params.get('email');
        const tenantUuid = params.get('tenant');
        const requestPasswordReset = new RequestPasswordResetUsingEmailAddress({emailAddress, tenantUuid});

        this.status = 'sending';

        const subscription =
            this.authService.sendResetPasswordEmail(requestPasswordReset)
                .subscribe({
                        next: () => this.status = 'sent',
                        error: (error) => this.handleError(error)
                    }
                );

        this.subscriptions.add(subscription);

    }

    private waitForUserInput(params: ParamMap): void {
        const [tenantUuid, token] = PasswordResetComponent.retrieveTenantAndToken(params);

        this.status = 'input';
        this.resetParams = new PasswordResetParams({tenantUuid, token});
    }

    public getFioriControlState(control: NgModel): Nullable<FormStates> {
        return this.fioriFormControlStateService.getFioriState(control);
    }

    public onResetClick(): void {
        this.status = 'resetting';

        const subscription =
            this.authService.resetPassword(this.resetParams)
                .subscribe({
                        next: () => this.status = 'reset',
                        error: (error) => this.handleError(error)
                    }
                );

        this.subscriptions.add(subscription);
    }

    public onResendClick(): void {
        this.status = 'sending';

        const subscription =
            this.route.queryParamMap.pipe(
                map(params => {
                    const [tenantUuid, token] = PasswordResetComponent.retrieveTenantAndToken(params);
                    return new RequestPasswordResetUsingToken({tenantUuid, token});
                }),
                switchMap(params => this.authService.sendResetPasswordEmail(params)),
            ).subscribe({
                    next: () => this.status = 'sent',
                    error: (error) => this.handleError(error)
                }
            );

        this.subscriptions.add(subscription);
    }

    private handleError(error): void {
        const isHttpError = typeof error === 'object' && error.status;
        const isExpiredToken = error.status === 410;

        this.status = isHttpError && isExpiredToken ? 'expired' : 'error';

        if (!isHttpError) {
            this.logger.error(error);
        }
    }

    private static retrieveTenantAndToken(params: ParamMap): string[] {
        const token = params.get('token');
        const tenant = params.get('tenant');

        if (!token) {
            throw 'Password Reset Error: No reset token provided';
        } else if (!tenant) {
            throw 'Password Reset Error: No tenant ID provided';
        }

        return [tenant, token];
    }
}
