import { Component, Input }                          from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component
({
    selector: 'use-form-control-error[controlCommonName][control]',
    templateUrl: './form-control-error.component.html',
    styleUrls: ['./form-control-error.component.scss']
})
export class FormControlErrorComponent {
    @Input() public controlCommonName: string;
    @Input() public control: AbstractControl | AbstractControlDirective;
    @Input() public overwriteErrorMessages: { [ error: string ]: string };
    @Input() public extendErrorMessages: { [ error: string ]: string };

    private get defaultErrorMessages() {
        return {
            exists: `This ${this.controlCommonName.toLowerCase()} is already in-use.`,
            password: 'The passwords entered do not match.',
            pattern: `This ${this.controlCommonName.toLowerCase()} is invalid.`,
            required: 'This field is required.',
        };
    }

    public get errorMessages() {
        if (this.extendErrorMessages) {
            return Object.entries({ ...this.defaultErrorMessages, ...this.overwriteErrorMessages })
                         .reduce((errorMessages, [key, value]) => {
                             if (this.extendErrorMessages[key]) {
                                 errorMessages[key] = `${value} ${this.extendErrorMessages[key]}`;
                             } else {
                                 errorMessages[key] = value;
                             }

                             return errorMessages;
                         }, { });
        } else {
            return { ...this.defaultErrorMessages, ...this.overwriteErrorMessages };
        }
    }
}
