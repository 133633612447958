import {Component, Input, TemplateRef} from '@angular/core';

@Component
({
    selector: 'use-status-board',
    templateUrl: './status-board.component.html',
    styleUrls: ['./status-board.component.scss']
})
export class StatusBoardComponent {
    @Input() public statuses: { [status: string]: TemplateRef<any> } = { };

    public currentStatus: TemplateRef<any> | null = null;

    @Input()
    public set status(status: string) {
        const statusTemplate = this.statuses[status];
        this.currentStatus = statusTemplate || null;
    }
}
