import {Action}        from './action.model';
import {ResourceBase}  from './resource-base.model';
import {default as Enumerable} from 'linq';

export class PortalMenuItem
    extends ResourceBase<number> {
    private _id: number;
    private _action: Action;
    private _name: string;
    private _description: string;
    private _hint: string;
    private _breadCrumb: string;
    private _iconName: string;
    private _sortOrder: number;
    private _parent?: PortalMenuItem;
    private _error: string;
    private _children: Array<PortalMenuItem>;

    public static fromItem(source: PortalMenuItem, parent?: PortalMenuItem): PortalMenuItem {
        const item: PortalMenuItem = new PortalMenuItem();

        item.id = source.id;
        item.iconName = source.iconName;
        item.sortOrder = source.sortOrder;
        item.name = source.name;
        item.description = source.description;
        item.action = source.action;
        item.hint = source.hint;
        item.breadCrumb = source.breadCrumb;
        item.parent = parent;
        item.error = source.error;
        item.children = PortalMenuItem.fromItems(source.children, item);

        return item;
    }

    public static fromItems(sourceItems: PortalMenuItem[], parent?: PortalMenuItem): PortalMenuItem[] {
        if (!sourceItems) {
return [];
}

        const items: PortalMenuItem[] = [];
        sourceItems.forEach(sourceItem => items.push(PortalMenuItem.fromItem(sourceItem, parent)));

        return Enumerable.from(items)
                  .orderBy(a => a.sortOrder)
                  .toArray();
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get action(): Action {
        return this._action;
    }

    set action(value: Action) {
        this._action = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get hint(): string {
        return this._hint;
    }

    set hint(value: string) {
        this._hint = value;
    }

    get breadCrumb(): string {
        return this._breadCrumb;
    }

    set breadCrumb(value: string) {
        this._breadCrumb = value;
    }

    get iconName(): string {
        return this._iconName;
    }

    set iconName(value: string) {
        this._iconName = value;
    }

    get sortOrder(): number {
        return this._sortOrder;
    }

    set sortOrder(value: number) {
        this._sortOrder = value;
    }

    get error(): string{
        return this._error;
    }

    set error(value: string) {
        this._error = value;
    }

    get children(): Array<PortalMenuItem> {
        return this._children;
    }

    set children(value: Array<PortalMenuItem>) {
        this._children = value;
    }

    get hasImageUrlIcon(): boolean {
        return typeof this.iconName === 'string' && this.iconName.trim().startsWith('url(');
    }

    get parent(): PortalMenuItem | undefined {
        return this._parent;
    }

    set parent(value: PortalMenuItem | undefined) {
        this._parent = value;
    }
}
