import {APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {FundamentalNgxCoreModule} from '@fundamental-ngx/core';
import {AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';

import {FormsModule as UseFormsModule} from '../../shared/component/forms/forms.module';
import {LoginComponent} from './login/login.component';
import {RegistrationComponent} from './registration/registration.component';
import {SharedModule} from '../../shared/shared.module';
import {DomainContextComponent} from './domain-context/domain-context.component';
import {VerificationComponent} from './verification/verification.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {AuthService} from './auth.service';
import {AuthClient} from './auth.client';
import {authAppInitializerFactory} from './auth-app-initializer.factory';
import {AuthGuardWithForcedLogin} from './auth-guard-with-forced-login.service';
import {FundamentalNgxPlatformModule} from '@fundamental-ngx/platform';
import {environment} from '../../../environments/environment';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

// Template variables within module config requires us to use a factory so that variables can be expanded before usage.
export function oauthModuleConfigFactory(): OAuthModuleConfig {
    if (environment.authModuleConfig.initialized) {
        return environment.authModuleConfig;
    }

    environment.authModuleConfig.resourceServer.allowedUrls = environment!.
        authModuleConfig!.
        resourceServer!.
        allowedUrls!.
        map(url => url.replace('${server.baseUrl}', environment.server.baseUrl));

    environment.authModuleConfig.initialized = true;

    return environment.authModuleConfig;
}

@NgModule
({
     declarations: [
         LoginComponent,
         RegistrationComponent,
         DomainContextComponent,
         VerificationComponent,
         PasswordResetComponent,
         ForgotPasswordComponent,
     ],
     imports: [
         CommonModule,
         FormsModule,
         ReactiveFormsModule,
         FundamentalNgxCoreModule,
         UseFormsModule,
         SharedModule,
         RouterModule,
         FundamentalNgxPlatformModule,
         OAuthModule.forRoot(),
     ],
     exports: [
         LoginComponent,
         RegistrationComponent,
     ],
     providers: [
         AuthService,
         AuthClient,
         AuthGuardWithForcedLogin,
     ]
 })
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    useFactory: authAppInitializerFactory,
                    deps: [AuthService],
                    multi: true
                },
                {provide: AuthConfig, useValue: environment.tenant.authConfig},
                {provide: OAuthModuleConfig, useFactory: oauthModuleConfigFactory},
                {provide: OAuthStorage, useFactory: storageFactory},
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error('AuthModule is already loaded. Import it in the CoreModule only');
        }
    }
}
