import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ErrorHandler} from '../error-handler.service';
import {WebClient} from '../../shared/client/web.client';
import {SsoRelay} from '../model/resource/sso-relay.model';

@Injectable
({
    providedIn: 'root'
})
export class SsoClient
    extends WebClient {
    public constructor(
        protected http: HttpClient,
        protected errorHandler: ErrorHandler) {
        super(
            http,
            errorHandler);
    }

    public createIdpInitiatedSsoRelay(
        endpointId: number,
        relayState: string,
        audience: string,
        context: any
    ): Observable<SsoRelay> {
        let url: string = `/sso/endpoint/${endpointId}/relay?` +
            `state=${relayState}` +
            `&audience=${audience}`;

        url = this.toApiUrl(url);
        const httpOptions: object = this.addContentTypeJson({});

        return this.http.post<SsoRelay>(
            url,
            context,
            httpOptions)
            .pipe(catchError(error => this.handleError(error)));
    }
}
