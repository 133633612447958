import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Action} from './model/resource/action.model';
import {SsoClient} from './client/sso.client';
import {SsoRelay} from './model/resource/sso-relay.model';


@Injectable
({
    providedIn: 'root'
})
export class SsoService {
    constructor(private ssoClient: SsoClient) {
    }

    public getIdpInitiatedSsoRelay(action: Action): Observable<SsoRelay> {
        const params: any = JSON.parse(action.paramsJson);
        const relayState: string = params.url;
        const context: any = params.context;
        const endpointId = action.id;
        const audience = context.ssoAudience;
        return this.ssoClient.createIdpInitiatedSsoRelay(endpointId, relayState, audience, context);
    }
}
