import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, AbstractControlDirective, NgModel} from '@angular/forms';

import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

import {FioriFormControlStateService} from '../../forms/fiori-form-control-state.service';
import {RegistrationParams} from '../../model/registration-params.model';
import {AuthService} from '../auth.service';
import {ErrorHandler} from '../../error-handler.service';
import {Tenant} from '../../model/resource/tenant.model';
import {Domain} from '../../model/resource/domain.model';
import {AlertConfig, AlertService, FormStates} from '@fundamental-ngx/core';

@Component
({
    selector: 'use-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent
    implements OnInit {
    @ViewChild('email', {static: false}) private emailControl: NgModel;
    @ViewChild('registrationSuccess', {static: false}) private successAlertTemplate: TemplateRef<any>;
    @ViewChild('registrationFailure', {static: false}) private failureAlertTemplate: TemplateRef<any>;

    private static readonly ALERT_REGISTRATION_ERR = 'Unable to complete registration.';

    public loading: boolean;
    public selectedTabIndex: number;
    public organizationName: string;
    public params: RegistrationParams;
    public confirmPass: string;

    public tenants: Tenant[];

    public constructor(
        private alertService: AlertService,
        private authService: AuthService,
        private errorHandler: ErrorHandler,
        private fioriControlStateService: FioriFormControlStateService,
    ) {
    }

    public ngOnInit() {
        this.initComponentState();
    }

    public getFioriControlState(control: AbstractControl | AbstractControlDirective): Nullable<FormStates> {
        return this.fioriControlStateService.getFioriState(control);
    }

    public onAcceptEmailClick(): void {
        this.fetchDomain();
    }

    public onBackClick(): void {
        this.selectedTabIndex--;

        if (this.selectedTabIndex < 1) {
            this.initComponentState();
        }
    }

    public onRegisterClick(): void {
        this.loading = true;
        this.authService.register(this.params)
            .subscribe({
                    next: () => this.handleRegistrationSuccess(),
                    error: (error: any) => this.handleRegistrationError(error)
                }
            );
    }

    private handleRegistrationSuccess(): void {
        this.initComponentState();
        this.displayRegistrationSuccessAlert();
    }

    private handleRegistrationError(error: any): void {
        this.displayRegistrationErrorAlert();
        this.initComponentState();
    }

    private initComponentState(): void {
        this.loading = false;
        this.selectedTabIndex = 0;
        this.organizationName = '';
        this.confirmPass = '';
        this.params = new RegistrationParams();
    }

    private fetchDomain() {
        const email: string = this.params.emailAddress!;
        const subscription: Subscription =
            this.authService
                .getDomainInfo(email)
                .pipe(tap(() => this.loading = true))
                .subscribe({
                    next:(domain: Domain) => this.handleDomainSuccess(domain),
                    error: (error: any) => this.handleDomainError(error),
                    complete: () => subscription.unsubscribe()}
                );
    }

    private handleDomainSuccess(domain: Domain): void {
        this.tenants = domain.tenants;
        this.organizationName = domain.organization.name;
        this.selectedTabIndex = 1;
        this.loading = false;
        this.params = new RegistrationParams({
            emailAddress: this.params.emailAddress
        });
    }

    private handleDomainError(error: any): void {
        this.displayRegistrationErrorAlert();
        this.initComponentState();
    }

    private displayRegistrationErrorAlert(): void {
        this.displayAlertAsError(RegistrationComponent.ALERT_REGISTRATION_ERR);
    }

    private displayRegistrationSuccessAlert(): void {
        this.alertService.open(this.successAlertTemplate, <AlertConfig>{
            type: 'success',
            duration: -1
        });
    }

    private displayAlertAsError(message: string): void {
        this.alertService.open(message, <AlertConfig>{
            type: 'error',
            dismissible: false
        });
    }
}
