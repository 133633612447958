import {Component, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';

import {PortalMenuItem} from '../../../core/model/resource/portal-menu-item.model';
import {AppBrowserService} from './app-browser.service';

export interface PortalBreadCrumb
{
    name: string;
    navTo: PortalMenuItem;
}

@Component
({
    selector: 'use-app-browser',
    templateUrl: './app-browser.component.html',
    styleUrls: ['./app-browser.component.scss']
})
export class AppBrowserComponent
    implements OnInit {
    public get apps$(): Observable<PortalMenuItem[]> {
        const parent = this.appBrowserService.currentMenuParent;

        return parent && parent.children ? of(parent.children) : of();
    }

    public get breadcrumbs(): PortalBreadCrumb[] {
        return this.appBrowserService.breadcrumbs;
    }

    public get isTopMenuLevel(): boolean {
        return this.appBrowserService.isTopMenuLevel;
    }

    public isCurrentMenuItem(menuItem: PortalMenuItem): boolean {
        return this.appBrowserService.isCurrentMenuItem(menuItem);
    }

    public onItemNav(params: any) {
        if (params.navToSubMenu === true && params.menuItem) {
            this.appBrowserService.currentMenuParent = params.menuItem;
        }
    }

    public navTo(menuItem: PortalMenuItem): void {
        this.appBrowserService.navTo(menuItem);
    }

    public constructor(private appBrowserService: AppBrowserService) {
    }

    public ngOnInit() {
        this.appBrowserService.getLatestPortalMenu()
            .subscribe(parent => {
                this.appBrowserService.currentMenuParent = parent;
            });
    }
}
