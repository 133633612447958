import { NgModule }                  from '@angular/core';
import { CommonModule }              from '@angular/common';

import { ButtonModule, DialogModule } from '@fundamental-ngx/core';

import { DialogService }             from './dialog.service';
import { DialogComponent }           from './dialog/dialog.component';
import { DialogDeleteComponent }     from './dialog-delete/dialog-delete.component';
import { PipeModule }                from '../../pipe/pipe.module';

@NgModule
({
    declarations: [
        DialogComponent,
        DialogDeleteComponent
    ],
    imports: [
        ButtonModule,
        CommonModule,
        DialogModule,
        PipeModule,
    ],
    exports: [
        DialogComponent,
        DialogDeleteComponent
    ],
    providers: [
        DialogService
    ]
})
export class DialogsModule { }
