import { ActionType }      from './action-type.model';
import { AuditEntryType }  from './audit-entry-type.model';
import { Language }        from './language.model';

export class ReferenceData {
    private _actionTypes: ActionType[] = [];
    private _auditEntryTypes: AuditEntryType[] = [];
    private _languages: Language[] = [];

    public static from(referenceData: ReferenceData): ReferenceData {
        const refData: ReferenceData = new ReferenceData();

        refData.actionTypes = referenceData.actionTypes.map(ActionType.from);
        refData.auditEntryTypes = referenceData.auditEntryTypes.map(AuditEntryType.from);
        refData.languages = referenceData.languages.map(Language.from);

        return refData;
    }

    get actionTypes(): ActionType[] {
        return this._actionTypes;
    }

    set actionTypes(value: ActionType[]) {
        this._actionTypes = value;
    }

    get auditEntryTypes(): AuditEntryType[] {
        return this._auditEntryTypes;
    }

    set auditEntryTypes(value: AuditEntryType[]) {
        this._auditEntryTypes = value;
    }

    get languages(): Language[] {
        return this._languages;
    }

    set languages(value: Language[]) {
        this._languages = value;
    }
}
