import {Location} from "@angular/common";
import {Injectable, OnDestroy} from '@angular/core';
import {NavigationExtras, Router, UrlSerializer} from '@angular/router';

import {BehaviorSubject, combineLatest, EMPTY, from, Observable} from 'rxjs';
import {concatMap, filter, map, tap} from 'rxjs/operators';

import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {NGXLogger} from 'ngx-logger';

import {AuthClient} from './auth.client';
import {Domain} from '../model/resource/domain.model';
import {environment} from '../../../environments/environment';
import {RequestPasswordResetUsingEmailAddress} from '../model/request-password-reset-using-email-address.model';
import {RequestPasswordResetUsingToken} from '../model/request-password-reset-using-token.model';
import {RegistrationParams} from '../model/registration-params.model';
import {VerificationParams} from '../model/verification-params.model';
import {VerificationResendParams} from '../model/verification-resend-params.model';
import {PasswordResetParams} from '../model/password-reset-params.model';
import {Tenant} from '../model/resource/tenant.model';
import {safeObserve} from '../../shared/extensions';
import {UserClient} from "../client/user.client";
import {SubscriptionManager} from "../../shared/subscription-manager";

@Injectable
({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {

    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);

    public get hasValidAccessToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    public get isAuthenticated$(): Observable<boolean> {
        return this.isAuthenticatedSubject$.asObservable();
    }

    private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);

    public get isDoneLoading$(): Observable<boolean> {
        return this.isDoneLoadingSubject$.asObservable();
    }

    /**
     * Publishes `true` if and only if (a) all the asynchronous initial
     * login calls have completed or errorred, and (b) the user ended up
     * being authenticated.
     *
     * In essence, it combines:
     *
     * - the latest known state of whether the user is authorized
     * - whether the ajax calls for initial log in have all been done
     */
    public get canActivateProtectedRoutes$(): Observable<boolean> {
        return combineLatest(
            [
                this.isAuthenticated$,
                this.isDoneLoading$
            ])
            .pipe(map(values => values.every(b => b)));
    }

    constructor(
        private oauthService: OAuthService,
        private router: Router,
        private location: Location,
        private urlSerializer: UrlSerializer,
        private authClient: AuthClient,
        private userClient: UserClient,
        private logger: NGXLogger,
        private subscriptionManager: SubscriptionManager
    ) {

        // Useful for debugging:
        let sub = this.oauthService.events
            .subscribe
            (event => {
                if (event instanceof OAuthErrorEvent) {
                    console.error('OAuthErrorEvent Object:', event);
                } else {
                    console.warn('OAuthEvent Object:', event);
                }
            });

        this.subscriptionManager.add(sub);

        // This is tricky, as it might cause race conditions (where access_token is set in another
        // tab before everything is said and done there.
        // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2

        window.addEventListener('storage', (event) => {

            // The `key` is `null` if the event was caused by `.clear()`
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }

            console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');

            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.oauthService.hasValidAccessToken()) {
                safeObserve(this.login());
            }
        });

        sub = this.oauthService.events.subscribe
        (_ => {
            logger.debug(`Has valid access token: ${this.oauthService.hasValidAccessToken()}`);
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        });
        this.subscriptionManager.add(sub);

        sub = this.oauthService.events
            .pipe(filter(e => ['token_received'].includes(e.type)))
            .pipe(concatMap(_ => this.oauthService.loadUserProfile()))
            .pipe(concatMap((response: { info: {preferred_username?: String}}) => {

                const info = response.info;
                if (!!info && this.hasValidAccessToken && typeof (info.preferred_username) === 'string') {
                    return this.verifyPortalUser();
                } else {
                    this.logout();
                    return EMPTY;
                }
            }))
            .subscribe();
        this.subscriptionManager.add(sub);

        sub = this.oauthService.events
            .pipe(filter(e => [
                'session_terminated',
                'session_error'
            ].includes(e.type)))
            .pipe(concatMap(() => this.login()))
            .subscribe();
        this.subscriptionManager.add(sub);

        this.oauthService.setupAutomaticSilentRefresh();
    }

    public ngOnDestroy() {
        this.subscriptionManager?.destroy();
    }

    public getDomainInfo(emailAddress: string): Observable<Domain> {
        return this.authClient.getDomainInfo(emailAddress);
    }

    public getUserDomainInfo(emailAddress: string): Observable<Domain> {
        return this.authClient.getUserDomainInfo(emailAddress);
    }

    public getCurrentRealm(): string | null {

        if (!environment.authModuleConfig.initialized) {
            return null;
        }

        const issuer = this.oauthService.issuer;
        if (!issuer) {
            return null;
        }

        const {groups: {realm}}: any | null = issuer.match(/.*\/realms\/(?<realm>[\w-_]+)$/);

        return realm || null;
    }

    /**
     * 1. Checks if a default tenant has been set in local store.
     * 1.1 If not, go to tenant login UI.
     * 2. Fetches tenant details from server.
     * 3. Loads the OIDC metadata from the IdP for the current tenant (realm).
     * 4. Attempts to complete an OAUTH flow via parsing the current query parameters (or hash fragment).
     * 4.1 If in possession of valid token, exit.
     * 5. Try silent token refresh.
     * 5.1 If succeeded, go to 6.
     * 5.2 If error indicated user interaction/login is required, redirect to login page.
     * 6. If state present, redirect to URL obtained from state.
     */
    public runInitialLoginSequence(): Observable<any> {

        if (!this.hasDefaultTenant) {
            return this.login();
        }

        if (location.hash && !environment.production) {

            console.log('Encountered hash fragment, plotting as table...');
            console.table(location.hash.substr(1)
                .split('&')
                .map(kvp => kvp.split('=')));
        }

        const result = this.authClient

            // 0. LOAD CONFIG:
            // First we have to check to see how the IdServer is
            // currently configured:

            .getTenant(this!.defaultTenant!.uuid).toPromise()
            .then(tenant => this.configureOAuth(tenant!.realm))
            .then(() => this.oauthService.loadDiscoveryDocument())

            // 1. HASH LOGIN:
            // Try to log in via hash fragment after redirect back
            // from IdServer from initImplicitFlow:
            .then(() => this.oauthService.tryLogin())

            .then(() => {

                // If login via hash fragment was successful, login is complete.
                if (this.oauthService.hasValidAccessToken()) {
                    return Promise.resolve();
                }

                // 2. SILENT LOGIN:
                // Try to log in via a refresh because then we can prevent
                // needing to redirect the user:
                return this.oauthService
                    .refreshToken()
                    .then(() => Promise.resolve())
                    .catch(result => {
                        // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                        // Only the ones where it's reasonably sure that sending the
                        // user to the IdServer will help.
                        const errorResponsesRequiringUserInteraction = [
                            'interaction_required',
                            'login_required',
                            'account_selection_required',
                            'consent_required',
                        ];

                        if (errorResponsesRequiringUserInteraction.includes(result?.reason?.error)) {

                            // 3. ASK FOR LOGIN:
                            // At this point we know for sure that we have to ask the
                            // user to log in, so we redirect them to the IdServer to
                            // enter credentials.
                            //
                            // Enable this to ALWAYS force a user to login.
                            // this.login();

                            return this.login().toPromise();
                        }

                        // We can't handle the truth, just pass on the problem to the next handler.
                        return Promise.reject(result);
                    });
            })

            .then(() => {

                this.isDoneLoadingSubject$.next(true);

                // Check for the strings 'undefined' and 'null' just to be sure. Our current
                // login(...) should never have this, but in case someone ever calls
                // initImplicitFlow(undefined | null) this could happen.
                if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
                    let stateUrl = this.oauthService.state;
                    if (!stateUrl.startsWith('/')) {
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
                    this.router.navigateByUrl(stateUrl).then();
                }
            })

            .catch(err => {

                this.logger.error(`Exception while running initial login sequence: ${err}`, err);
                this.isDoneLoadingSubject$.next(true);
                return this.login().toPromise();
            });

        return from(result);
    }

    public login(targetUrl?: string): Observable<any> {

        const url = this.urlSerializer.parse(this.location.path(false));
        const path: Nullable<string> = url?.root?.children?.primary?.segments[0]?.path;

        if (path?.toLowerCase() !== 'login') {

            const extras = <NavigationExtras>{
                state: { targetUrl: targetUrl ?? 'home'}
            };

            safeObserve(from(this.router.navigate(['login'], extras)));
        }

        return EMPTY;
    }

    public loginToTenant
    (
        username?: string,
        targetUrl: Nullable<string> = null,
        externalIdp: boolean = false,
        idpHint: Nullable<string> = null
    ): Observable<void> {

        this.configureOAuth(this.defaultTenant!.realm);

        const result = this.oauthService
            .loadDiscoveryDocument()
            .then(() => {

                const url = targetUrl || this.router.url;
                let state = {};

                if (!externalIdp) {
                    const selectedUserName = username || this.getCurrentUserEmail();
                    state = { ...state, login_hint: selectedUserName};
                }

                if (typeof idpHint === 'string') {
                    state = {...state, kc_idp_hint: idpHint};
                }

                this.oauthService.initCodeFlow(url, state);
            });

        return from(result);
    }

    private configureOAuth(realm: string) {

        const config = Object.assign({}, environment.tenant.authConfig);
        config.issuer = config!.issuer!
            .replace('${keycloak.baseUrl}', environment.keycloak.baseUrl)!
            .replace('${realm}', realm);

        this.oauthService.configure(config);
    }

    public logout() {

        try {
            this.clearDefaultTenant();
        } catch (e) {
            // trap exception
        }

        try {
            this.oauthService.logOut();
        } catch (e) {
            // trap exception
        }
    }

    public async refresh() {
        await this.oauthService.silentRefresh();
    }

    public get identityClaims(): object {
        return this.oauthService.getIdentityClaims();
    }

    public get idToken(): string {
        return this.oauthService.getIdToken();
    }

    public get hasDefaultTenant(): boolean {
        return this.defaultTenant !== null;
    }

    public setDefaultTenant(value: Tenant) {

        localStorage.setItem('tenant-id', value.uuid);
        localStorage.setItem('realm', value.realm);
    }

    public clearDefaultTenant() {

        localStorage.removeItem('tenant-id');
        localStorage.removeItem('realm');
    }

    public get defaultTenant(): Nullable<Tenant> {

        const tenantId = localStorage.getItem('tenant-id');
        const realm = localStorage.getItem('realm');

        return !!tenantId && !!realm
            ? <Tenant>{
                uuid: localStorage.getItem('tenant-id')!,
                realm: localStorage.getItem('realm')!
            }
            : null;
    }

    public getCurrentUserEmail(): string {
        return this.getIdentityClaim('email');
    }

    public sendResetPasswordEmail(params: RequestPasswordResetUsingEmailAddress): Observable<void>;
    public sendResetPasswordEmail(params: RequestPasswordResetUsingToken): Observable<void>;
    public sendResetPasswordEmail(params: RequestPasswordResetUsingEmailAddress | RequestPasswordResetUsingToken): Observable<void> {
        return params instanceof RequestPasswordResetUsingEmailAddress
            ? this.authClient.resendResetPasswordEmailUsingEmailAddress(params)
            : this.authClient.resendResetPasswordEmailUsingToken(params);
    }

    public register(params: RegistrationParams): Observable<void> {
        return this.authClient.register(params);
    }

    public verify(params: VerificationParams): Observable<void> {
        return this.authClient.verify(params);
    }

    public resendVerificationEmail(params: VerificationResendParams): Observable<void> {
        return this.authClient.resendVerificationEmail(params);
    }

    public resetPassword(params: PasswordResetParams): Observable<void> {
        return this.authClient.resetPassword(params);
    }

    public initTenantFromTenantId(tenantId: string): Observable<any> {

        return this.authClient
            .getTenant(tenantId)
            .pipe(tap(t => this.setDefaultTenant(t)));
    }

    private getIdentityClaim(name: string): string {
        return this?.identityClaims?.[name] || null;
    }

    public verifyPortalUser(): Observable<void> {
        return this.authClient.verifyPortalUser();
    }
}
