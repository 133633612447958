import {EventEmitter, Injectable} from '@angular/core';

import {NGXLogger} from 'ngx-logger';

import {PortalMenuItem} from '../../../core/model/resource/portal-menu-item.model';
import {Action} from '../../../core/model/resource/action.model';
import {ActionTypeEnum} from '../../../core/model/action-type.enum';
import {Assert} from '../../../core/debug-tools';
import {SsoService} from '../../../core/sso.service';
import {SsoRelay} from '../../../core/model/resource/sso-relay.model';

@Injectable
({
     providedIn: 'root'
 })
export class PortalAppService {
    public constructor(
        private logger: NGXLogger,
        private ssoService: SsoService) {
    }

    public checkExecuteAction(
        menuItem: PortalMenuItem,
        event: EventEmitter<any>) {
        if (menuItem.action) {
            this.dispatchAction(menuItem.action);
        }

        if (menuItem.children && menuItem.children.length > 0) {
            // Render a new dashboard with the child items

            this.logger.debug(`Rendering child dashboard of menu item "${menuItem.name}"`);

            event.emit({menuItem, navToSubMenu: true, openNewTab: false});
        }
    }

    public executeClientNavAction(action: Action) {
        const params: any = JSON.parse(action.paramsJson);
        this.openUrlInNewTab(params.url);
    }

    private dispatchAction(action: Action) {
        if (!action) {
            this.logger.error('Action dispatched without action object.');
            return;
        }

        switch (action.actionTypeId) {
            case ActionTypeEnum.ClientNavToUrl:
                this.executeClientNavAction(action);
                break;
            case ActionTypeEnum.ClientNavToUnsolicatedSsoUrl:
                this.executeClientNavToUnsolicitedSsoUri(action);
                break;
        }
    }

    private openUrlInNewTab(url: string) {
        Assert.isTrue(!!url);
        window.open(url, '_blank');
    }

    private executeClientNavToUnsolicitedSsoUri(action: Action) {
        this.ssoService
            .getIdpInitiatedSsoRelay(action)
            .subscribe(relay => {
                           const wnd = window.open(relay.acsUri, '_blank');
                           if (!wnd) {
                               throw Error;
                           }
                           wnd.document.open();
                           wnd.document.write(relay.html);
                           wnd.document.close();
                       });
    }
}
