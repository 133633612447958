import {ModelBase} from '../model-base.model';

export abstract class ResourceBase<TKey>
    extends ModelBase {

    public constructor() {
        super();
    }

    public abstract get id(): TKey;
}
