<fd-layout-panel [attr.aria-busy]="loading">
    <fd-layout-panel-header>
        <fd-layout-panel-head>
            <h1 class="fd-has-font-weight-bold fd-has-type-5 fd-has-color-text-2">
                <use-button-with-backplate *ngIf="selectedTabIndex !== 0"
                                           (click)="onBackClick()">
                </use-button-with-backplate>
                Register
            </h1>
        </fd-layout-panel-head>
    </fd-layout-panel-header>
    <fd-layout-panel-body>
        <fd-busy-indicator [loading]="loading"></fd-busy-indicator>
        <ng-container *ngIf="selectedTabIndex === 0">
            <form>
                <div fd-form-set
                     class="form-set-width-1">
                    <div fd-form-item>
                        <input fd-form-control
                               required
                               id="email"
                               name="email"
                               type="email"
                               maxlength="255"
                               placeholder="Your organization email address"
                               useValidEmailAddress
                               #email="ngModel"
                               [state]="getFioriControlState(email)"
                               [(ngModel)]="params.emailAddress">
                        <use-form-control-error controlCommonName="email address"
                                                [control]="email">
                        </use-form-control-error>
                    </div>
                </div>
                <div fd-form-item
                     class="fd-has-padding-top-small">
                    <button (click)="onAcceptEmailClick()"
                            [disabled]="!!(email.errors || email.pending)"
                            class="fd-has-padding-left-medium fd-has-padding-right-medium"
                            fd-button>
                        Continue
                    </button>
                    <a class="inline-link"
                       [routerLink]="['../login']">
                        Already registered?
                    </a>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="selectedTabIndex === 1">
            <use-domain-context [organizationName]="organizationName"
                                [userIdentifier]="params.emailAddress!">
            </use-domain-context>
            <form #registrationForm="ngForm"
                  useConfirmPassword>
                <ng-container>
                    <div fd-form-set>
                        <div fd-form-item>
                            <label fd-form-label
                                   class="required"
                                   for="environment">
                                Environment
                            </label>
                            <fd-select id="environment"
                                       [(value)]="params.tenantUuid">
                                <fd-option *ngFor="let tenant of tenants"
                                           [value]="tenant.uuid">
                                    {{ tenant.environment.name }}
                                </fd-option>
                            </fd-select>
                        </div>
                    </div>
                </ng-container>
                <div fd-form-set>
                    <div fd-form-item>
                        <label fd-form-label
                               class="required"
                               for="firstName">
                            First name
                        </label>
                        <input fd-form-control
                               required
                               id="firstName"
                               name="firstName"
                               type="text"
                               maxlength="50"
                               #firstName="ngModel"
                               [state]="getFioriControlState(firstName)"
                               [(ngModel)]="params.firstName">
                        <use-form-control-error controlCommonName="first name"
                                                [control]="firstName">
                        </use-form-control-error>
                    </div>
                </div>
                <div fd-form-set>
                    <div fd-form-item>
                        <label fd-form-label
                               class="required"
                               for="lastName">
                            Last name
                        </label>
                        <input fd-form-control
                               required
                               id="lastName"
                               name="lastName"
                               type="text"
                               maxlength="50"
                               #lastName="ngModel"
                               [state]="getFioriControlState(lastName)"
                               [(ngModel)]="params.lastName">
                        <use-form-control-error controlCommonName="last name"
                                                [control]="lastName">
                        </use-form-control-error>
                    </div>
                </div>
                <div fd-form-set
                     class="form-set-width-1">
                    <div fd-form-item>
                        <label fd-form-label
                               class="required"
                               for="password">
                            Password
                        </label>
                        <input fd-form-control
                               required
                               id="password"
                               name="password"
                               type="password"
                               #password="ngModel"
                               [state]="getFioriControlState(password)"
                               [(ngModel)]="params.password">
                        <use-form-control-error controlCommonName="password"
                                                [control]="password">
                        </use-form-control-error>
                    </div>
                </div>
                <div fd-form-set
                     class="form-set-width-1">
                    <div fd-form-item>
                        <label fd-form-label
                               class="required"
                               for="confirmPassword">
                            Confirm password
                        </label>
                        <input fd-form-control
                               required
                               id="confirmPassword"
                               name="confirmPassword"
                               type="password"
                               #confirmPassword="ngModel"
                               [state]="getFioriControlState(confirmPassword)"
                               [(ngModel)]="confirmPass">
                        <use-form-control-error controlCommonName="password confirmation"
                                                [control]="confirmPassword">
                        </use-form-control-error>
                    </div>
                </div>
                <div fd-form-item
                     class="fd-has-padding-top-small">
                    <button (click)="onRegisterClick()"
                            [disabled]="registrationForm.invalid"
                            class="fd-has-padding-left-medium fd-has-padding-right-medium"
                            fd-button>
                        Register
                    </button>
                    <a class="inline-link"
                       [routerLink]="['../login']">
                        Already registered?
                    </a>
                </div>
            </form>
        </ng-container>
    </fd-layout-panel-body>
    <fd-layout-panel-footer>
        EPI-USE (c) 2019
    </fd-layout-panel-footer>
</fd-layout-panel>

<ng-template #registrationSuccess>
    <p>You have been successfully registered. Please check your email inbox for a verification link.</p>
</ng-template>

<ng-template #registrationFailure>
    <p>Unable to complete registration. An error has occurred.</p>
    <p>If you have already registered but have not yet verified your email address, please check your email inbox for the verification email and follow the instructions in the email.</p>
    <p>If you are still unable to register please contact support.</p>
</ng-template>
