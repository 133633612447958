import {default as Enumerable} from 'linq';

import {Assert} from '../../core/debug-tools';
import {ResourceBase} from '../../core/model/resource/resource-base.model';

export class EntityChangeSet<TEntity extends ResourceBase<TKey>, TKey> {
    private _created: TEntity[] = [];
    private _modified: TEntity[] = [];
    private _deleted: TKey[] = [];

    /**
     * An entity can only be added to the create list if it is not present in any tracking list.
     * Trying to add a created entity already present in any of the tracking lists is invalid.
     *
     * @param entity
     */
    public addCreated(entity: TEntity) {
        const modified = Enumerable.from(this._modified);
        const created = Enumerable.from(this._created);
        const deleted = Enumerable.from(this._deleted);

        Assert.isTrue(!created.any(a => a === entity));
        Assert.isTrue(!modified.any(a => a === entity));
        Assert.isTrue(!deleted.any(a => a === entity.id));

        this._created.push(entity);
    }

    /**
     * An entity will only be added to the modified list if:
     * 1) The entity is not already in the modified list.
     * 2) The entity is not in the created list.
     *
     * The entity should be in the deleted list; we do an assert to guard against this illegal state.
     *
     * @param entity
     */
    public addModified(entity: TEntity) {
        const modified = Enumerable.from(this._modified);
        const created = Enumerable.from(this._created);
        const deleted = Enumerable.from(this._deleted);

        // There must not be a deleted entry for this entity. This would be in invalid state.

        Assert.isTrue(!deleted.any(a => a === entity.id));

        // Only add a modified entry if not already in the modified or created lists.

        if (!created.any(a => a === entity) &&
            !modified.any(a => a === entity)) {
            this._modified.push(entity);
        }
    }

    /**
     * An entity will only be added to the deleted list if:
     * 1) It is not already in the deleted list.
     *
     * An entity that is in the modified list at the time addDeleted is called:
     * 1) Is removed from the modified list and added to the deleted list.
     *
     * An entity that is in the created list at the time addDeleted is called:
     * 1) Will be removed from the created list.
     * 2) Will not be added to the deletd list since the entity has not been persisted yet. This will effectively
     *      delete the entity locally.
     *
     * @param entityId
     */
    public addDeleted(entityId: TKey) {
        if (!Enumerable.from(this._deleted)
            .any(a => a === entityId)) {
            // Check if the entity is already in the modified list.
            // If so remove it since it's being deleted.

            let entity = this.getModifiedById(entityId);
            if (entity) {
                this.removeModified(entity);
            }

            // Check if the entity is already in the created list.
            // If so it only needs to be removed from the created list to delete it from the local store.
            // It is not added to the deleted list since the entity has not yet been persisted.

            entity = this.getCreatedById(entityId);

            if (entity) {
                this.removeCreated(entity);
            } else {
                this._deleted.push(entityId);
            }
        }
    }

    public getModifiedById(id: TKey): TEntity | undefined {
        return Enumerable.from(this._modified)
            .firstOrDefault(a => a.id === id);
    }

    public getCreatedById(id: TKey): TEntity | undefined {
        return Enumerable.from(this._created)
            .firstOrDefault(a => a.id === id);
    }

    public removeModified(entity: TEntity) {
        const index = this._modified.indexOf(entity);

        if (index !== -1) {
            this._modified.splice(index, 1);
        }
    }

    public removeCreated(entity: TEntity) {
        const index = this._created.indexOf(entity);

        if (index !== -1) {
            this._created.splice(index, 1);
        }
    }

    get created(): TEntity[] {
        return <TEntity[]>(JSON.parse(JSON.stringify(this._created)));
    }

    get modified(): TEntity[] {
        return <TEntity[]>(JSON.parse(JSON.stringify(this._modified)));
    }

    get deleted(): TKey[] {
        return <TKey[]>(JSON.parse(JSON.stringify(this._deleted)));
    }

    public hasChanges(): boolean {
        return this._created.length > 0 ||
            this._modified.length > 0 ||
            this._deleted.length > 0;
    }

    public toJson(): string {
        const obj: any =
            {
                created: this._created,
                modified: this._modified,
                deleted: this._deleted
            };

        return JSON.stringify(obj);
    }

    public static fromJson<TEntity extends ResourceBase<TKey>, TKey>(json: string): EntityChangeSet<TEntity, TKey> {
        const obj: any = JSON.parse(json);
        const changes = new EntityChangeSet<TEntity, TKey>();
        changes._created = obj.created || [];
        changes._modified = obj.modified || [];
        changes._deleted = obj.deleted || [];
        return changes;
    }
}
