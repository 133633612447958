import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {UserAppContext} from '../model/resource/user-app-context.model';
import {AppContextSelectEnum} from '../model/app-context-select.enum';
import {WebClient} from '../../shared/client/web.client';
import {ErrorHandler} from '../error-handler.service';
import {NGXLogger} from 'ngx-logger';

@Injectable
({
    providedIn: 'root'
})
export class UserClient
    extends WebClient {

    public static readonly BASE_USER_PATH = '/user';

    private static readonly DEFAULT_SELECT =
        [
            AppContextSelectEnum.ACTION_TYPE,
            AppContextSelectEnum.AUDIT_ENTRY_TYPE,
            AppContextSelectEnum.LANGUAGES,
        ];

    public constructor(
        protected http: HttpClient,
        protected errorHandler: ErrorHandler,
        private logger: NGXLogger) {
        super(
            http,
            errorHandler);
    }

    public getUserAppContext(...selectFields: AppContextSelectEnum[]): Observable<UserAppContext> {
        const url: string = this.toApiUrl(`/user/app-context`);
        const params: object = UserClient.buildParams(selectFields);
        const httpOptions: object = this.addContentTypeJson({params});
        return this.http.get<UserAppContext>(
            url,
            httpOptions)
            .pipe(tap(c => {
                this.logger.debug('Received context: ', c);
            }))
            .pipe(catchError(error => {
                this.logger.error(error);
                return this.handleError(error);
            }));
    }

    private static buildParams(selectFields: AppContextSelectEnum[]): { select: string } {
        if (!selectFields || selectFields.length == 0) {
            selectFields = UserClient.DEFAULT_SELECT;
        }
        return {select: selectFields.join(',')};
    }
}
