import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {NGXLogger} from 'ngx-logger';

@Injectable
({
    providedIn: 'root'
})
export class ErrorHandler {
    public constructor(private logger: NGXLogger) {
    }

    public handleError(original: any): Observable<never> {
        let message: string;

        if (original instanceof HttpErrorResponse) {
            const response: HttpErrorResponse = <HttpErrorResponse>original;
            const error = JSON.stringify(response.error);

            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,

            message = `Server returned code ${response.status}, body was: ${error}`;
        } else if (original instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            message = (<ErrorEvent>original).message;
        } else if (original instanceof Error) {
            message = (<Error>original).message;
        } else {
            message = original.toString();
        }

        this.logger.error(`An error occurred: ${message}`);

        return throwError(original);
    }
}
