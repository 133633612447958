import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AbstractControl, AbstractControlDirective, FormBuilder, FormGroup, Validators,} from '@angular/forms';

import {Observable, EMPTY, of} from 'rxjs';
import {concatMap, map, tap} from 'rxjs/operators';

import {AlertConfig, AlertService, FormStates} from '@fundamental-ngx/core';
import {default as Enumerable} from 'linq';
import {NGXLogger} from 'ngx-logger';

import {AuthService} from '../auth.service';
import {ErrorHandler} from '../../error-handler.service';
import {FioriFormControlStateService} from '../../forms/fiori-form-control-state.service';
import {Tenant} from '../../model/resource/tenant.model';
import {EmailAddressAsyncValidator} from '../../../shared/validators/email-address.async-validator';
import {SubscriptionManager} from '../../../shared/subscription-manager';
import {safeObserve} from "../../../shared/extensions";

@Component
({
    selector: 'use-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent
    implements OnInit, OnDestroy {

    private static readonly ALERT_AUTH_ERR_MSG = 'An authentication failure has occurred.';

    private _selectedEnvironmentId: string | null;
    private _selectedTenant: Nullable<Tenant>;
    private _targetUrl: Nullable<string> = null;
    private _idpHint: Nullable<string> = null;
    private _externalIdp: boolean = false;

    public selectedTabIndex = 0;
    public userNamePlaceholder = 'Your organization email address';
    public tenants: Tenant[] = [];

    public userNameForm: FormGroup = this.fb.group
    ({
        userName: [
            '',
            [
                Validators.required,
                Validators.maxLength(254)
            ],
            [new EmailAddressAsyncValidator()]
        ]
    });

    public get userNameControl(): AbstractControl {
        return this.userNameForm!.get('userName')!;
    }

    public get selectedUserName(): string {
        return this.userNameControl.value;
    }

    public tenantForm: FormGroup = this.fb.group({});

    public get selectedTenant(): Tenant | null {
        return this._selectedTenant;
    }

    public set selectedEnvironmentId(value: string | null) {

        if (!value) {
            this._selectedTenant = null;
            this._selectedEnvironmentId = null;
            this.authService.clearDefaultTenant();
            return;
        }

        const selected = Enumerable.from(this.tenants)
            .firstOrDefault(a => a.environment.id.toString() === value);

        if (!selected) {
            return;
        }

        this._selectedTenant = selected;
        this.authService.setDefaultTenant(this._selectedTenant);

        this._selectedEnvironmentId = value;
    }

    public get selectedEnvironmentId(): string | null {
        return this._selectedEnvironmentId;
    }

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private logger: NGXLogger,
        private errorHandler: ErrorHandler,
        private alertService: AlertService,
        private subscriptionManager: SubscriptionManager,
        public fioriControlStateService: FioriFormControlStateService) {
    }

    public getFioriControlState(control: AbstractControlDirective | AbstractControl): Undefinable<FormStates> {
        let state: FormStates | null = this.fioriControlStateService.getFioriState(control);
        if (state === null) {
            return undefined;
        }
        return state;
    }

    public ngOnInit(): void {

        const email = this.authService.getCurrentUserEmail();
        if (!!email) {
            this.userNameControl.setValue(email);
        }

        const sub = this.route
            .queryParamMap
            .pipe(
                concatMap((paramMap: ParamMap) => this.processParamMapChange$(paramMap)),
                concatMap(_ => this.checkTryAutoAuthenticationRedirect$())
            ).subscribe();

        this.subscriptionManager.add(sub);
    }

    public ngOnDestroy() {
        this.subscriptionManager?.destroy();
    }

    public onAcceptUserNameClick(_: MouseEvent) {

        const sub = this.checkTryAutoAuthenticationRedirect$()
            .subscribe({
                error: _ => this.displayAuthenticationFailureAlert(),
                complete: () => sub.unsubscribe()
            });
    }

    public OnAcceptTenantClick(_: MouseEvent) {
        safeObserve(this.tryAuthenticateUser$());
    }

    public onCancelClick() {
        this.resetLoginState();
    }

    private resetLoginState() {

        this.selectedEnvironmentId = null;
        this.tenants = [];
        this.userNameForm.reset();
        this.tenantForm.reset();
        this.selectedTabIndex = 0;
    }

    private processParamMapChange$(paramMap: ParamMap): Observable<ParamMap> {

        if (typeof paramMap === 'undefined' || paramMap === null) {
            return EMPTY;
        }

        const idpHint = this._idpHint = paramMap.has('idpHint') ? paramMap.get('idpHint') : null;
        const externalIdp = this._externalIdp = paramMap.has('externalIdp');
        this._targetUrl = paramMap.has('targetUrl')
            ? paramMap.get('targetUrl')
            : 'home';

        // Only process email arg if there is no external IDP.
        // For external IDPs the email address is obtained from the IDP authentication callback.

        if (!externalIdp && paramMap.has('email')) {
            this.userNameControl.setValue(paramMap.get('email'));
        }

        if ((externalIdp || !!idpHint) && paramMap.has('tenantId')) {

            return this.authService
                .initTenantFromTenantId(paramMap.get('tenantId')!)
                .pipe(
                    tap(_ => {
                        this._selectedTenant = this.authService.defaultTenant!;
                        this.tenants = [this._selectedTenant!];
                    }),
                    map(_ => paramMap));
        }

        return of(paramMap);
    }

    private checkTryAutoAuthenticationRedirect$(): Observable<void> {

        if (!this._externalIdp && this.selectedUserName) {

            // Lookup the tenant only if no tenant is selected and if a username is present.
            return this.lookupUserTenant()
                .pipe(concatMap(tenant => {

                    // If there is only one tenant, proceed to authenticate automatically since
                    // we have all information required...

                    if (this._selectedTenant && this.tenants.length === 1) {
                        return this.tryAuthenticateUser$();
                    }

                    // If there is more than one tenant, focus the tenant selection tab
                    // so that the user can select one.

                    this.selectedTabIndex = 2;
                    return EMPTY;
                }));
        }

        else if (this._externalIdp && this._selectedTenant) {
            return this.tryAuthenticateUser$();
        }

        return EMPTY;
    }

    private tryAuthenticateUser$(): Observable<void> {

        this.selectedTabIndex = 2;

        return this.authService.loginToTenant(
            this.selectedUserName,
            this._targetUrl,
            this._externalIdp,
            this._idpHint);
    }

    private lookupUserTenant(defaultRealm?: string): Observable<Nullable<Tenant>> {

        // Lookup the user tenant based on username.

        return this.authService
            .getUserDomainInfo(this.selectedUserName)
            .pipe(map((result) => {

                    if (result && result.tenants && result.tenants.length && result.tenants.length > 0) {
                        this.tenants = result.tenants;
                        const i: number = !!defaultRealm ? this.indexOfTenantOrDefault(defaultRealm, 0) : 0;
                        this.selectedEnvironmentId = this.tenants[i].environment.id.toString();
                    }
                    else {
                        this.selectedEnvironmentId = null;
                    }

                    return this._selectedTenant
                }));
    }

    private indexOfTenantOrDefault(realm: string, defaultValue: number): number {
        const index = this.tenants.findIndex(t => t.realm === realm);
        return index === -1 ? defaultValue : index;
    }

    private displayAuthenticationFailureAlert(): void {
        this.displayAlert('error', LoginComponent.ALERT_AUTH_ERR_MSG);
    }

    private displayAlert(
        type: string,
        message: string): void {
        this.alertService.open(message, <AlertConfig>{
            type,
            dismissible: false,
            duration: 10000
        });
    }
}
