export class Role {
    public id: number | null = null;
    public roleGroupId: number | null = null;
    public isSystemRole = false;
    public name: string | null = null;
    public description: string | null = null;
    public enableContextSelection = false;

    /**
     * Role model.
     *
     * @param           [initValues]                           Model property initialization values
     * @param           [initValues.id]                        Role ID
     * @param           [initValues.roleGroupId]               Role role-group ID
     * @param          [initValues.name]                      Role name
     * @param          [initValues.description]               Role description
     * @param          [initValues.enableContextSelection]    Role context selection flag
     */
    public constructor(initValues: Partial<Role> = {}) {
        Object.assign(this, initValues);
    }

    /**
     * Static copy constructor.
     *
     * @param role Model instance to copy
     */
    public static from(role: Role): Role {
        return new Role({
            id: role.id,
            roleGroupId: role.roleGroupId,
            isSystemRole: role.isSystemRole,
            name: role.name,
            description: role.description,
            enableContextSelection: role.enableContextSelection
        });
    }
}
