import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {WebClient} from '../../shared/client/web.client';
import {ErrorHandler} from '../error-handler.service';
import {Domain} from '../model/resource/domain.model';
import {RegistrationParams} from '../model/registration-params.model';
import {VerificationParams} from '../model/verification-params.model';
import {VerificationResendParams} from '../model/verification-resend-params.model';
import {PasswordResetParams} from '../model/password-reset-params.model';
import {RequestPasswordResetUsingEmailAddress} from '../model/request-password-reset-using-email-address.model';
import {RequestPasswordResetUsingToken} from '../model/request-password-reset-using-token.model';
import {Tenant} from '../model/resource/tenant.model';

@Injectable
({
    providedIn: 'root'
})
export class AuthClient
    extends WebClient {

    public static readonly BASE_USER_PATH = '/user';

    public constructor(
        protected http: HttpClient,
        protected errorHandler: ErrorHandler) {
        super(http, errorHandler);
    }

    public getDomainInfo(emailAddress: string): Observable<Domain> {

        const url = `/domain/${emailAddress}`;
        return this.http
            .get<Domain>(this.toAuthUrl(url), this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public getUserDomainInfo(emailAddress: string): Observable<Domain> {

        const url: string = this.toAuthUrl(`/domain/user/${emailAddress}`);
        return this.http
            .get<Domain>(url, this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public getTenant(uuid: string): Observable<Tenant> {

        const url = `/tenant/${uuid}`;
        return this.http.get<Tenant>(this.toAuthUrl(url), this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public register(params: RegistrationParams): Observable<void> {

        const url: string = this.toAuthUrl('/register');
        const body: any = {
            firstName: params.firstName,
            lastName: params.lastName,
            emailAddress: params.emailAddress,
            password: params.password
        };

        return this.http
            .post<void>(url, body, this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public verify(params: VerificationParams): Observable<void> {

        const url: string = this.toAuthUrl('/verify');
        const body: object = {token: params.token};
        return this.http
            .post<void>(url, body, this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public resendVerificationEmail(params: VerificationResendParams): Observable<void> {

        const url: string = this.toAuthUrl('/verification-email');
        const body: object = {emailAddress: params.emailAddress};
        return this.http
            .post<void>(url, body, this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public resetPassword(params: PasswordResetParams): Observable<void> {

        const url: string = this.toAuthUrl('/reset-password');
        const body: object = {token: params.token, password: params.password};
        return this.http
            .post<void>(url, body, this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public resendResetPasswordEmailUsingEmailAddress(
        params: RequestPasswordResetUsingEmailAddress
    ): Observable<void> {

        const url: string = this.toAuthUrl('/reset-password-email');
        const body: object = {emailAddress: params.emailAddress};
        return this.http
            .post<void>(url, body, this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public resendResetPasswordEmailUsingToken(params: RequestPasswordResetUsingToken): Observable<void> {

        const url: string = this.toAuthUrl('/reset-password-token');
        const body: object = {token: params.token};
        return this.http
            .post<void>(url, body, this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)));
    }

    public verifyPortalUser(): Observable<void> {
        const url: string = this.toApiUrl(`${AuthClient.BASE_USER_PATH}/verify-portal-user`);
        return this.http
            .post<void>(url, {}, this.standardJsonHttpMethodOptions())
            .pipe(catchError(error => this.handleError(error)))
    }
}
