export enum EnvironmentEnum
{
    DEVELOPMENT = 1,
    QA = 2,
    PRE_PRODUCTION = 3,
    PRODUCTION = 4,
    TRAINING = 5,
    DEMO = 6,
    DEV_CI = 7,
    TEST = 8,
    ALL = 9
}
