import {Injectable} from '@angular/core';

import {iif, Observable, of} from 'rxjs';
import {distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';

import {UserClient} from './client/user.client';
import {AuthService} from './auth/auth.service';
import {AppContext} from './model/app-context.model';
import {NGXLogger} from 'ngx-logger';

@Injectable
({
     providedIn: 'root'
 })
export class AppContextProvider {

    public context$: Observable<AppContext> = of(new AppContext());

    public constructor(
        private userClient: UserClient,
        private auth: AuthService,
        private logger: NGXLogger) {

        this.context$ = auth.isAuthenticated$
            .pipe(distinctUntilChanged((x, y) => x === y))
            .pipe(switchMap(a => iif(() => a === true, userClient.getUserAppContext(), of(new AppContext()))))
            .pipe(tap(c => {
                logger.debug(`Context: userLangId=${c?.user?.userLangId}`);
            }))
            .pipe(map(AppContext.from));

    }
}
