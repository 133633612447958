class MediaType {

    public static CONTENT_TYPE = 'Content-Type';
    public static APPLICATION_JSON = 'application/json';
    public static APPLICATION_JSON_PATCH = 'application/json-patch+json';
}

export class Constants {
    public static MediaType = MediaType;
}
