import {Pipe, PipeTransform} from '@angular/core';

@Pipe
({
    name: 'nobrhyphen'
})
export class NobrHyphenPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {

        if (!value) {
return value;
}

        if (typeof(value) !== 'string') {
return;
}

        const str = value.toString();
        if (String.isNullOrEmpty(str)) {
return null;
}

        return str.replace('-', '\u2011');
    }
}
