<use-dialog #dialog>
    <p class="fd-padding-begin-end--sm">
        Are you sure you wish to remove this {{ dialogRef.data?.resourceName | lowercase }}?
    </p>
    <ng-container class="dialog-actions">
        <fd-button-bar fdType="emphasized"
                       [compact]="true"
                       (click)="onConfirmDelete()">
            Remove
        </fd-button-bar>
        <fd-button-bar fdType="standard"
                       [compact]="true"
                       (click)="dialog.onCancel()">
            Cancel
        </fd-button-bar>
    </ng-container>
</use-dialog>
