import {
    HttpClient,
    HttpContext,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams
} from '@angular/common/http';

import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {ErrorHandler} from '../../core/error-handler.service';
import {Constants} from '../../core/constants';
import {HttpHeadersBuilder} from '../http-headers-builder';
import {tenantId} from '../extensions';

export class JsonHttpMethodOptions {

    headers?: HttpHeaders;
    context?: HttpContext;
    params?: HttpParams;
}

export abstract class WebClient {

    public static readonly baseUrl: string = environment.services.baseUrl;
    public static readonly apiUrlPrefix: string = environment.services.apiPath;
    public static readonly authUrlPrefix: string = environment.services.authPath;

    public constructor(
        protected http: HttpClient,
        protected errorHandler: ErrorHandler) {
    }

    protected standardJsonRequestHeaders(): HttpHeaders {

        return new HttpHeadersBuilder()
            .addContentTypeJsonHeader()
            .addTenantIdHeader(tenantId())
            .build();
    }

    protected standardJsonHttpMethodOptions(params?: HttpParams): JsonHttpMethodOptions {

        return <JsonHttpMethodOptions>{
            params: params,
            headers: this.standardJsonRequestHeaders()
        };
    }

    protected addContentTypeJson(httpOptions: any): any {

        const headers: HttpHeaders = httpOptions.headers
            ? {...httpOptions.headers}
            : {};
        return {
            ...httpOptions,
            headers: {...headers, ...{[Constants.MediaType.CONTENT_TYPE]: Constants.MediaType.APPLICATION_JSON}}
        };
    }

    protected addContentTypeJsonPatch(httpOptions: any): any {

        const headers: HttpHeaders = httpOptions.headers
            ? {...httpOptions.headers}
            : {};
        return {
            ...httpOptions,
            headers: {...headers, ...{[Constants.MediaType.CONTENT_TYPE]: Constants.MediaType.APPLICATION_JSON_PATCH}}
        };
    }

    protected toApiUrl(relativeUrl: string): string {
        return WebClient.addBaseUrl(
            WebClient.apiUrlPrefix,
            relativeUrl);
    }

    protected toAuthUrl(relativeUrl: string): string {
        return WebClient.addBaseUrl(
            WebClient.authUrlPrefix,
            relativeUrl);
    }

    protected handleError(error: HttpErrorResponse): Observable<never> {
        return this.errorHandler.handleError(error);
    }

    public static addBaseUrl(
        area: string,
        path: string): string {
        return `${WebClient.baseUrl}${area}${path}`;
    };
}
