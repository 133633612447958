import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgModel} from '@angular/forms';

import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

import {FioriFormControlStateService} from '../../forms/fiori-form-control-state.service';
import {FormStates} from '@fundamental-ngx/core';

@Component
({
    selector: 'use-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent
    implements OnDestroy, OnInit {
    public status: 'error' | 'reset';

    public resetParams: { tenantId: string; emailAddress: string };
    private subscriptions: Subscription;

    public constructor(
        private fioriFormControlStateService: FioriFormControlStateService,
        private route: ActivatedRoute
    ) { }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnInit(): void {
        this.initComponent();
    }

    private initComponent(): void {
        this.resetParams = { } as any;
        this.subscriptions = new Subscription();

        this.initComponentState();
    }

    private initComponentState(): void {
        this.status = 'reset';

        const subscription =
            this.route.queryParamMap
                .pipe(tap(params => {
                   const tenant = params.get('tenant');

                   if (!tenant) {
                       throw 'Unable to continue: No tenant ID provided';
                   }

                   this.resetParams.tenantId = tenant;
                }))
                .subscribe({
                        next: () => {},
                        error: () => this.status = 'error'
                    }
                );

        this.subscriptions.add(subscription);
    }

    public getFioriControlState(control: NgModel): Nullable<FormStates> {
        return this.fioriFormControlStateService.getFioriState(control);
    }
}
