<fd-panel>
    <div class="panel panel-centered">
        <div class="error">
            <fd-icon size="xl"
                     glyph="message-error">
            </fd-icon>
        </div>
        <div>
            <p class="error panel__emphasized-text">Not Found</p>
            <p>The page being requested could not be found</p>
        </div>
    </div>
</fd-panel>
