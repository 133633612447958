import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonType} from '@fundamental-ngx/core';

@Component({
  selector: 'use-button-with-backplate',
  templateUrl: './button-with-backplate.component.html',
  styleUrls: ['./button-with-backplate.component.scss']
})
export class ButtonWithBackplateComponent {
    @Input() public fdType: ButtonType;
    @Input() public glyph = 'nav-back';
    @Output() public click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
}
