import {Injectable} from '@angular/core';

import {environment} from '../../../environments/environment';
import {ModelBase} from './model-base.model';
import {PortalMenu} from './resource/portal-menu.model';
import {UserProfile} from './resource/user-profile.model';
import {UserAppContext} from './resource/user-app-context.model';
import {ReferenceData} from './resource/reference-data.model';
import {Language} from './resource/language.model';
import {Role} from './resource/role.model';
import {Environment} from './resource/environment.model';

Injectable
({
     providedIn: 'root'
 });

export class AppContext
    extends ModelBase {
    private readonly _appTitle: string = environment.appTitle;

    private _user: UserProfile | null = null;
    private _portalMenu: PortalMenu;
    private _referenceData: ReferenceData;
    private _userRoles: Role[] = [];
    private _environment: Environment;

    public constructor() {
        super();

        this.initialize();
    }

    public reset() {
        this.initialize();
    }

    get appTitle(): string {
        return this._appTitle;
    }

    get portalMenu(): PortalMenu {
        return this._portalMenu;
    }

    set portalMenu(value: PortalMenu) {
        this._portalMenu = value;
    }

    get user(): UserProfile | null {
        return this._user;
    }

    set user(value: UserProfile | null) {
        this._user = value;
    }

    get currentLanguageId() {
        return this.user?.userLangId;
    }

    get referenceData(): ReferenceData {
        return this._referenceData;
    }

    set referenceData(referenceData: ReferenceData) {
        this._referenceData = referenceData;
    }

    get userRoles(): Role[] {
        return this._userRoles;
    }

    set userRoles(value: Role[]) {
        this._userRoles = value;
    }

    get languages(): Language[] {
        return this._referenceData.languages;
    }

    get environment(): Environment {
        return this._environment;
    }

    set environment(value: Environment) {
        this._environment = value;
    }

    public static from(userAppContext: UserAppContext): AppContext {
        const context: AppContext = new AppContext();

        if (!userAppContext) {
            return context;
        }

        context.portalMenu = PortalMenu.from(userAppContext.portalMenu);
        context.user = UserProfile.from(userAppContext.user);
        context.referenceData = ReferenceData.from(userAppContext.referenceData);
        context.userRoles = userAppContext.userRoles.map(Role.from);
        context.environment = userAppContext.environment;

        return context;
    }

    private initialize() {
        this._portalMenu = new PortalMenu();
        this._referenceData = new ReferenceData();
        this._user = null;
        this._userRoles = [];
        this._environment = new Environment();
    }
}
