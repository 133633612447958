import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';

import {EMPTY, Observable} from 'rxjs';

import {AuthService} from '../auth.service';
import {environment} from 'src/environments/environment';
import {safeObserve} from '../../../shared/extensions';

@Injectable
({
    providedIn: 'root'
})
export class AuthInterceptor
    implements HttpInterceptor {

    public constructor(private authService: AuthService) {
    }

    public intercept
    (
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if (!req.url.includes('/ewp/api')) {
            return next.handle(req);
        }
        // All API requests require a valid access token.

        if (!this.authService.hasValidAccessToken) {

            // Logout in next turn
            setTimeout(() => {
                safeObserve(this.authService.login());
            }, 0);

            // Cancel request.
            return EMPTY;
        }

        const headers = this.addAuthHeaders({});
        const clonedRequest = req.clone({setHeaders: headers});
        return next.handle(clonedRequest);
    }

    private addAuthHeaders(headers: any): any {

        if (this.authService.hasDefaultTenant) {
            headers[environment.tenantIdHeaderKey] = this.authService!.defaultTenant!.uuid;
        }

        return headers;
    }
}
