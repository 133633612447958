import {Component} from '@angular/core';

import {DialogRef} from '@fundamental-ngx/core';

import {DialogCloseReasonEnum} from '../dialog-close-reason.enum';

@Component
({
    selector: 'use-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

    public constructor(
        public dialogRef: DialogRef
    ) {
    }

    public onCancel(): void {
        this.dialogRef.dismiss(DialogCloseReasonEnum.CANCEL);
    }
}
