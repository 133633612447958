<use-status-board [status]="status"
                  [statuses]="{
                    'error': error,
                    'reset': reset
                  }">
</use-status-board>

<ng-template #error>
    <div class="panel panel-centered">
        <div class="error">
            <fd-icon glyph="message-error">
            </fd-icon>
        </div>
        <div>
            <p class="error panel__emphasized-text">Error</p>
            <p>Unable to continue.</p>
            <p>Something unexpected occurred.</p>
        </div>
    </div>
</ng-template>

<ng-template #reset>
    <div class="panel">
        <div class="neutral panel__emphasized-text">
            Forgot password?
        </div>
        <div>
            You can request a recovery email by providing your email address below.
        </div>
        <form>
            <fdp-form-group>
                <fdp-form-field-group>
                    <fdp-form-field
                        class="form-set-width-1">
                        <div fd-form-item>
                            <label fd-form-label
                                   for="email">
                                Email address
                            </label>
                            <input fd-form-control
                                   required
                                   id="email"
                                   name="email"
                                   type="email"
                                   maxlength="255"
                                   useValidEmailAddress
                                   #email="ngModel"
                                   [state]="getFioriControlState(email)"
                                   [(ngModel)]="resetParams.emailAddress">
                            <use-form-control-error controlCommonName="email address"
                                                    [control]="email">
                            </use-form-control-error>
                        </div>
                    </fdp-form-field>
                </fdp-form-field-group>
            </fdp-form-group>
            <button fd-button
                    [disabled]="email.invalid"
                    [routerLink]="['../reset-password']"
                    [queryParams]="{
                       email: resetParams.emailAddress,
                       tenant: resetParams.tenantId
                    }">
                Send
            </button>
            <a class="panel__inline-link"
               [routerLink]="['../login']">
                Back to login
            </a>
        </form>
    </div>
</ng-template>
