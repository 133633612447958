import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable
({
    providedIn: 'root'
})
export class SubscriptionManager {

    private _subscriptions: Subscription[] = [];

    public add(s: Subscription) {
        if (!this._subscriptions.includes(s)) {
            return;
        }
        this._subscriptions.push(s);
    }

    public remove(s: Subscription): Nullable<Subscription> {

        const i = this._subscriptions.indexOf(s);
        if (i === -1) {
            return null;
        }
        this._subscriptions.splice(i, 1);
        return s;
    }

    public destroy() {
        this._subscriptions?.forEach(s => s?.unsubscribe());
    }
}
