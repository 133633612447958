import {ResourceBase} from './resource-base.model';

export class UserProfile
    extends ResourceBase<number> {
    private _id: number;
    private _firstName: string | null;
    private _lastName: string | null;
    private _initials: string | null;
    private _colorAccent: string | null;
    private _image: string | null;
    private _userLangId: number | null;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get firstName(): string | null {
        return this._firstName;
    }

    set firstName(value: string | null) {
        this._firstName = value;
    }

    get lastName(): string | null {
        return this._lastName;
    }

    set lastName(value: string | null) {
        this._lastName = value;
    }

    get initials(): string | null {
        return this._initials;
    }

    set initials(value: string | null) {
        this._initials = value;
    }

    get colorAccent(): string | null {
        return this._colorAccent;
    }

    set colorAccent(value: string | null) {
        this._colorAccent = value;
    }

    get image(): string | null {
        return this._image;
    }

    set image(value: string | null) {
        this._image = value;
    }

    get userLangId(): number | null {
        return this._userLangId;
    }

    set userLangId(value: number | null) {
        this._userLangId = value;
    }

    public static from(userProfile: UserProfile): UserProfile | null {
        if (!userProfile) {
            return null;
            // throw new Error('Cannot map user profile: User profile was undefined or null');
        }

        return <UserProfile>
            {
                ...userProfile,
                colorAccent: '6',
                image: null,
            };
    }
}
