import {Injectable}          from '@angular/core';
import {Router, RouterEvent} from '@angular/router';
import {filter}              from 'rxjs/operators';
import {NGXLogger}           from 'ngx-logger';
import {error}               from 'selenium-webdriver';
import InvalidArgumentError = error.InvalidArgumentError;

@Injectable
({
     providedIn: 'root'
 })
export class DebugTools {
    public constructor(private router: Router,
                       private logger: NGXLogger) {
    }

    public initialize() {
        this.initRouterEventLogging();
    }

    private initRouterEventLogging() {
        this.router
            .events
            .pipe(filter(e => e instanceof RouterEvent))
            .subscribe(
                (e: RouterEvent) => {
                    this.logger.info(`Router event: ${e.constructor.name} (${e.id}, ${e.url})`);
                });
    }
}

export class AssertionError
    extends Error {
}

@Injectable
({
    providedIn: 'root'
 })
export class Assert {
    public static isTrue(value: any, message?: string) {
        if (value !== true) {
            message = message || `Expected "true" but received ${value}`;
            throw new AssertionError(`Assertion failed: ${message}`);
        }
    }
}
