import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, of}                   from 'rxjs';

@Injectable
({
    providedIn: 'root'
 })
export class BusyIndicator {
    private _busy = false;
    private readonly _busy$: Observable<boolean> = of(this._busy);

    @Output()
    public busyEvent: EventEmitter<boolean> = new EventEmitter(true);

    public get busy$(): Observable<boolean> {
        return this._busy$;
    }

    get busy(): boolean {
        return this._busy;
    }

    set busy(value: boolean) {
        if (this.busy !== value) {
            this._busy = value;
            this.busyEvent.emit(this.busy);
        }
    }
}
