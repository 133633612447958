export class ActionType {
    private _id: number;
    private _name: string;
    private _description: string;
    private _actionClass: string;

    public static from(actionType: ActionType): ActionType {
        const actType: ActionType = new ActionType();

        actType.id = actionType.id;
        actType.name = actionType.name;
        actType.description = actionType.description;
        actType.actionClass = actionType.actionClass;

        return actType;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get actionClass(): string {
        return this._actionClass;
    }

    set actionClass(value: string) {
        this._actionClass = value;
    }
}
